import React from 'react'
import { Star } from 'react-feather'

export default function Stars(props) {
  const { rating, setRating, size = 42, className = 'mr-5' } = props

  return (
    <div className={['flex',  setRating && 'cursor-pointer']}>
      <Star
        size={size}
        onClick={setRating !== undefined ? () => setRating(1) : undefined}
        fill={rating > 0 ? '#E8C500' : '#e2dede'}
        className={[className, rating > 0 ? 'text-yellow' : 'text-gray-400']}
      />
      <Star
        size={size}
        onClick={setRating !== undefined ? () => setRating(2) : undefined}
        fill={rating > 1 ? '#E8C500' : '#e2dede'}
        className={[className, rating > 1 ? 'text-yellow' : 'text-gray-400']}
      />
      <Star
        size={size}
        onClick={setRating !== undefined ? () => setRating(3) : undefined}
        fill={rating > 2 ? '#E8C500' : '#e2dede'}
        className={[className, rating > 2 ? 'text-yellow' : 'text-gray-400']}
      />
      <Star
        size={size}
        onClick={setRating !== undefined ? () => setRating(4) : undefined}
        fill={rating > 3 ? '#E8C500' : '#e2dede'}
        className={[className, rating > 3 ? 'text-yellow' : 'text-gray-400']}
      />
      <Star
        size={size}
        onClick={setRating !== undefined ? () => setRating(5) : undefined}
        fill={rating > 4 ? '#E8C500' : '#e2dede'}
        className={[className, rating > 4 ? 'text-yellow' : 'text-gray-400']}
      />
    </div>
  )
}
