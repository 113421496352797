import React from 'react'

export default function Checkbox(props) {
  const {
    label = null,
    size,
    disabled,
    checked,
    defaultChecked,
    onChange,
    className,
    children,
    checkboxClassName,
    ...rest
  } = props

  return (
    <label
      className={[
        'checkbox',
        className,
      ]}
    >
      <input
        {...rest}
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={event => {
          onChange && onChange(event.target.checked, event)
        }}
      />
      <div 
        className={checkboxClassName} />
      {children || (<span>{label}</span>)}
    </label>
  )
}
