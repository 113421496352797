export const minimumDefaults = {
  catering: 600,
  dessert: 495,
}

const calculateMinimums = args => {
  const minimums = {
    main: 600,
    snack: 200,
    side: 200,
    appetizer: 200,
    dessert: 495,
    drink: 495,
    alcohol: 0,
  }

  return {
    ...minimums,
    main: args.catering,
    snack: args.catering,
    appetizer: args.catering,
    side: args.catering,
    dessert: args.dessert,
    drink: args.cateringTagPage ? 400 : minimums.drink,
  }
}

/**
 * Generates a list of prices per person
 * @param {*} args
 * @returns
 */
const calculatePrices = args => {
  const prices = {
    dessert: Math.max(args.catering / (400 / 3), 6), // $6.00
    main: args.catering / 100, // $8.00
    snack: args.catering / (400 / 3), // $6.00
    appetizer: args.catering / (400 / 3), // $6.00
    side: args.catering / (400 / 3), // $6.00
    drink: Math.max(args.catering / (400 / 3), 6), // $6.00
    alcohol: 0,
  }

  if (args.pricePerPerson) {
    return {
      ...prices,
      main: args.pricePerPerson,
    }
  }

  return prices
}

/**
 * Extra prices per person added when a minimum amount of eaters is found
 * @param {*} args 
 * @returns 
 */
const calculatePricesExtra = args => {
  if (!args.foods?.length || !args.eaters) {
    return 0
  }

  const rules = {
    'main,dessert,drink': {
      min_eaters: 50,
      price: 15,
    },
    'main,dessert': {
      min_eaters: 45,
      price: 13,
    },
    'main,drink': {
      min_eaters: 50,
      price: 12,
    },
    'dessert': {
      min_eaters: 80,
      price: 6,
    },
    'drink': {
      min_eaters: 80,
      price: 6,
    },
    'main': {
      min_eaters: 50,
      price: 10,
    },
  }

  for(const rule in rules) {
    const selectedRule = rule.split(',')
    if (
      args.eaters > rules[rule].min_eaters &&
      selectedRule.every(food => args.foods.includes(food))
    ) {
      return rules[rule].price * (args.eaters - rules[rule].min_eaters)
    }
  }

  return 0
}

export const calculate = args => {
  const eaters = args.eaters || 0

  if (eaters < 1) {
    return 0
  }

  const prices = calculatePrices(args)
  const minimums = calculateMinimums(args)

  let city_minimum = args.catering
  let guest_minimum = city_minimum / 2
  if (args.cateringTagPage) {
    guest_minimum = 400
  } else if (args.cuisinePage) {
    guest_minimum = city_minimum
  }

  let food_minimum = 0

  if (args.foods?.length) {
    args.foods.forEach(key => {
      guest_minimum = Math.max(guest_minimum, minimums[key.toLowerCase()])
      food_minimum += prices[key.toLowerCase()] * eaters
    })

    // Bump pricing on minimum amount of eaters
    food_minimum += calculatePricesExtra(args)
  } else {
    food_minimum += (args.pricePerPerson || prices.drink) * eaters
  }

  // Don't return a value below 800!
  const bc_minimum = Math.max(guest_minimum, food_minimum)

  // Round to closest $5
  return Math.ceil(bc_minimum / 5) * 5
}

export function getMiles(i) {
  return Math.ceil(i * 0.000621371192)
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
