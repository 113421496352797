import React from 'react'
import computer from 'assets/svg/computer.svg'
import cake from 'assets/svg/wedding-cake.svg'
import cap from 'assets/svg/graduation-cap.svg'
import mic from 'assets/svg/mic.svg'
import film from 'assets/svg/film.svg'
import balloons from 'assets/svg/balloons.svg'
import briefcase from 'assets/svg/briefcase.svg'
import partyHat from 'assets/svg/party-hat.svg'
import styles from './CateringForAnyEvent.module.css'
import LazyImage from 'app/theme/LazyImage'

export default function CateringForAnyEvent(props) {
  const {
    heading = 'Food Truck Catering For Any Event',
    description,
    placeSlug,
    ...rest
  } = props

  return (
    <div {...rest}>
      <div className="container py-16">
        <h2 className="font-platform leading-none text-navy text-4xl text-center mb-12">
          {heading}
        </h2>
        {description && (
          <p className="font-light text-gray-900 mt-6 text-center mb-6">{description}</p>
        )}
        <div className="md:flex md:flex-wrap md:-mx-4">
          <Item
            title="Corporate Catering"
            url="/food-truck-catering/corporate-catering/"
            icon={computer}
          >
            Catered Food Truck Lunch Programs, In-Office Meal Programs, Business Park Lunches, Employee Appreciation, Client Appreciation, Holiday Parties, Milestone Celebrations, Retail Events
          </Item>
          <Item
            title="Private Parties"
            url="/food-truck-catering/party-catering/"
            icon={partyHat}
          >
            Birthday, Holiday, Graduation, Anniversary, Bar/Bat Mitzvahs, Baby Shower, House Warming
          </Item>
          <Item
            title="Food Trucks on Campus"
            url="/food-truck-catering/campus-catering/"
            icon={cap}
          >
            Student Organizations, On/Off-Campus Events, Graduation, Prom, School Dances, School Fundraisers, Recruitment Events, Alumni Events
          </Item>
          <Item
            title="Large-Scale Events and Multi-City Event Series"
            url="/food-truck-catering/large-event-food-concessions/"
            icon={mic}
          >
            Sporting Events, Music Festivals, Arena Concerts, National Event Series, Marathons and Fun Runs
          </Item>
          <Item
            title="Production Catering"
            url="/food-truck-catering/film-production-catering/"
            icon={film}
          >
            Movie Set Catering, TV and Film Catering, 24 Hour Production Catering, On Location Catering, Photo Shoots
          </Item>
          <Item
            title="Community Events"
            url="/food-truck-catering/local-community-concessions/"
            icon={balloons}
          >
            Street Fairs, Neighborhood Block Parties, Flea Markets, Little League Games, Car Shows, Real Estate Open Houses, Religious Congregations and Ministries, High School Football Games, Concerts in the Park
          </Item>
          <Item
            title="Professional Events"
            url="/food-truck-catering/conference-and-event-catering/"
            icon={briefcase}
          >
            Conferences, Conventions, Expos
          </Item>
          <Item
            title="Wedding Catering"
            url="/food-truck-catering/food-truck-wedding/"
            icon={cake}
          >
            Wedding Receptions, Rehearsal Dinners, Late Night Snacks, Day-After Brunches
          </Item>
        </div>
      </div>
    </div>
  )
}

function Item({ children, url, icon, title }) {
  return (
    <div className={[styles.item, 'md:w-1/2 mb-5']}>
      <a href={url}>
        <i><LazyImage src={icon} /></i>
        <div>
          <h3 className="text-navy text-2xl md:text-xl">
            {title}
          </h3>
          <p className="font-light text-lg md:text-base">{children}</p>
        </div>
      </a>
    </div>
  )
}
