import React, { useState, useRef, useEffect } from 'react'
import { useQuery } from 'jsonapi-react'
import styles from './TagList.module.css'
import jump from 'jump.js'

export default function TagList(props) {
  const {
    heading = 'Explore Other Catering Types',
    subheading,
    baseUrl = '/',
    className,
    darkMode = false,
    place,
    ...rest
  } = props

  const ref = useRef()
  const [open, setOpen] = useState()
  const toggle = () => setOpen(!open)

  useEffect(() => {
    if (open === false) {
      jump(ref.current, {
        duration: 0,
      })
    }
  }, [open])

  return (
    <section
      ref={ref}
      {...rest}
      className={[
        className,
        darkMode ? "bg-navy text-white" : "bg-white text-navy",
        'text-center pt-16 pb-20',
      ]}
    >
      <div className={[styles.container, open && styles.open, darkMode ? styles.dark_mode : styles.light_mode]}>
        <h2 className={[
          darkMode ? "text-white" : "text-navy", 
          "font-platform mb-10 text-3xl lg:text-4xl"
        ]}>
          {heading}
        </h2>
        {subheading && (
          <p className='font-light text-lg text-black leading-snug mb-5 pb-16'>
            {subheading}
          </p>
        )}
        <div className={styles.cover}>
          <Tags baseUrl={baseUrl} place={place} />
        </div>
        <button
          className={[
            darkMode ? "text-white border-white" : "text-navy border-navy",
            "btn border-2 font-platform text-xl px-10 py-3"
          ]}
          onClick={() => toggle()}
        />
      </div>
    </section>
  )
}

function Tags({ baseUrl, place }) {

  const maxMiles = 10

  const areaFilter = {}
  if (place.type === 'region') {
    areaFilter.region_id = place.id
  } else if ((place.type === 'city' && place.major) || !place.location) {
    areaFilter.city_id = place.id
  } else {
    areaFilter.service_area = {
      contains: JSON.stringify({ ...place.location, radius: maxMiles })
    }
  }

  const query = useQuery(['tags', {
    sort: 'name',
    filter: {
      ...areaFilter
    },
    fields: {
      tags: ['name', 'slug']
    },
    extra_fields: {
      tags: ['total_caterers'],
    },
    page: {
      size: 999,
    },
    stats: {
      total: 'count',
    }
  }])

  if (query.isLoading) {
    return null
  }

  if (!query.data?.length || query.data?.length < 1) {
    return null
  }

  return (
    <ul>
      {query.data.map((tag, index) => (
        <li key={index}>
          <a href={baseUrl + '/' + tag.slug + '/'}>{tag.name}</a>
        </li>
      ))}
    </ul>
  )
}
