import React, { useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { Layout } from 'app/theme/layout'
import { Helmet } from 'react-helmet-async'
import { useQuery } from 'jsonapi-react'
import posthog from 'posthog-js'
import FoodTruckCatering from 'app/theme/sections/FoodTruckCatering'
import WhyWorkWithUs from 'app/theme/sections/WhyWorkWithUs'
import CateringForAnyEvent from 'app/theme/sections/CateringForAnyEvent'
import SaveTime from 'app/theme/sections/SaveTime'
import MakeEveryoneHappy from 'app/theme/sections/MakeEveryoneHappy'
import SitBackRelax from 'app/theme/sections/SitBackRelax'
import RepeatRepeat from 'app/theme/sections/RepeatRepeat'
import HowItWorks from 'app/theme/sections/HowItWorks'
import HowItWorksLarge from 'app/theme/sections/HowItWorksLarge'
import Calculator from 'app/theme/calculator'
import FeaturedClients from 'app/theme/sections/FeaturedClients'
import TagList from 'app/theme/sections/TagList'
import CateringReviews from 'app/theme/sections/CateringReviews'
import Slider from 'app/theme/slider'
import truckPic from 'assets/svg/truck.svg'
import NotFoundPage from 'app/pages/NotFoundPage'
import ErrorPage from 'app/pages/ErrorPage'
import LeadModal from 'app/leads/LeadModal'
import CatererCarousel from 'app/theme/CatererCarousel'
import { useModalState } from 'lib/modal'
import { getResizedPhotoUrl } from 'app/utils/photos'
import styles from './CityCateringPage.module.css'
import { useFeatureFlagVariantKey } from 'lib/posthog'

export default function CityCateringPage(props) {
  const { openModal, ...modalState } = useModalState()

  if (props.staticContext) {
    props.staticContext.cacheMaxAge = 604800
  }

  const params = useParams()

  const query = useQuery(['places', {
    basic: true,
    filter: {
      slug: params.citySlug,
    },
  }])

  const pageQuery = useQuery(['pages', {
    sort: '-priority',
    merge: true,
    expand_content: true,
    filter: {
      type: 'CityCatering',
      city: params.citySlug,
    },
  }])

  useEffect(() => {
    posthog.startSessionRecording()
    return () => {
      posthog.stopSessionRecording()
    }
  }, [])

  useEffect(() => {
    if (!query.isLoading && query.data?.length) {
      posthog.capture('$pageview', {
        city_id: query.data[0].id,
        city_name: query.data[0].name
      }, )
    }
  }, [query.isLoading])

  const place = query.data && query.data[0]
  const page = pageQuery.data ? pageQuery.data[0] : null

  // filter caterers by region_id or city_id based on the place type
  const catererQueryFilterKey = place?.type === 'region' ? 'region_id' : 'city_id'
  // if the place type is a neighborhood, use the place.city.id to filter caterers
  let catererQueryFilterValue = place?.type === 'neighborhood' ? place?.city?.id : place?.id
  if (!catererQueryFilterValue) {
    catererQueryFilterValue = place?.region?.id
  }

  // gets a list of caterers to use in the TruckSlider and FAQ section
  const caterersQuery = useQuery(place && ['caterers', {
    sort: '-ranking',
    filter: {
      [catererQueryFilterKey]: catererQueryFilterValue,
      closed: false
    },
    include: [
      'photo',
      'tags',
      'services',
      'good_fors',
    ],
    fields: {
      caterers: ['name', 'slug', 'description'],
      photos: ['uuid', 'extension', 'path'],
      tags: ['name'],
      good_fors: ['name'],
      caterer_services: ['type']
    },
    page: {
      size: 16
    },
    stats: {
      total: 'count',
    }
  }])

  const reviewsQuery = useQuery(place && place.bounding_box && ['reviews', {
    sort: '-created_at',
    filter: {
      rating: [4,5],
      bounding_box: place.bounding_box,
    },
    include: [
      'event',
      'event.address',
      'event.proposal.contact',
      'event.occasion',
      'review_tags'
    ],
    fields: {
      proposals: [],
      events: ['start_date', 'eaters'],
      occasions: ['name'],
      contacts: ['first_name', 'last_name']
    },
    page: {
      size: 5
    },
    stats: {
      total: 'count',
      rating: 'average'
    }
  }])

  if (query.isLoading || caterersQuery.isLoading || pageQuery.isLoading || reviewsQuery.isLoading) {
    return null
  }

  if (params.catererSlug) {
    return <Redirect to={`/${place.url_slug}/food-truck-catering/`} />
  }

  if (!query.data) {
    return <ErrorPage />
  }

  if (!query.data.length) {
    return <NotFoundPage />
  }

  if (['city', 'region'].includes(place.type) && place.url_slug != params.citySlug) {
    return <Redirect to={`/${place.url_slug}/food-truck-catering/`} />
  }

  const nameParts = []
  if (place.type === 'region') {
    nameParts.push(place.name, place.country?.code)
  } else if (place.country?.code === 'US') {
    nameParts.push(place.name, place.region?.code)
  } else {
    nameParts.push(place.name, place.country?.code)
  }

  const displayName = nameParts.filter(Boolean).join(', ')

  const googleMetaTags = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'FoodService',
    'subjectOf': {
      'name': `${place.name} Food Truck Catering FAQ`,
      '@type': 'FAQPage',
      url: `https://roaminghunger.com/${params.citySlug}/food-truck-catering/`,
      'mainEntity': [
        {
          '@type': 'Question',
          'name': `How much does it cost to rent a food truck in ${place.name}?`,
          'acceptedAnswer': {
            '@type': 'Answer',
            'text': `<p>On average renting a food truck for catering in ${place.name} costs $${place.catering_average_cost || "1,541"}, and costs typically range from $800-$2,500.</p> <p>Cuisine affects the costs of your food truck catering more than any other factor. Here are typical per person costs along with average minimums.</p> <div align=\'left\'> <table> <tbody> <tr> <td> <strong>Cuisine</strong> </td> <td> <strong>Average Price per Person</strong> </td> <td> <strong>1-100 Eaters (Minimum)</strong> </td> </tr> <tr> <td> <a href=\'https://roaminghunger.com/food-truck-catering/taco-truck-catering/\'>Taco Truck Catering</a> </td> <td> $10-15 </td> <td> $1,000 </td> </tr> <tr> <td> <a href=\'https://roaminghunger.com/food-truck-catering/burger-catering-truck/\'>Burger</a>,&nbsp;<a href=\'https://roaminghunger.com/food-truck-catering/bbq-food-truck-catering/\'>BBQ</a>, &amp;&nbsp;<a href=\'https://roaminghunger.com/food-truck-catering/pizza-truck-catering/\'>Pizza</a> Truck Catering </td> <td> $15-25 </td> <td> $1,500 </td> </tr> <tr> <td> Dessert Truck Catering </td> <td> $8-20 </td> <td> $800 </td> </tr> <tr> <td> Beverage Truck Catering </td> <td> $8-20 </td> <td> $800 </td> </tr> </tbody> </table> </div> <p>Use our&nbsp;<a href=\'https://roaminghunger.com/food-truck-catering/#calculator\'>Food Truck Catering Cost Calculator</a></p> <p>Adding drinks or dessert options (with a savory food truck) will increase the price per person by $1-$5.</p> <p>If you&rsquo;re having a small party of 50 guests or less, you&rsquo;ll probably be affected by the food truck catering minimums, and the price per person will increase.</p> <p>Typical service times are 2-3 hours. Additional hours will increase total costs. Travel fees can also affect pricing. Food trucks may charge a per mile fee if your event is outside their normal travel radius (20-30 miles).&nbsp;</p> <p>This data is based on actual Roaming Hunger data, and does not include any taxes or fees.</p>`
          }
        },
        {
          '@type': 'Question',
          'name': `What are the best food trucks in ${place.name} for catering?`,
          'acceptedAnswer': {
            '@type': 'Answer',
            'text': `<p>These are the food trucks that get booked the most for catering in ${place.name}:</p><ul>${caterersQuery.data?.slice(0,5).map(caterer => (`<li><a href="https://roaminghunger.com/${caterer.slug}/">${caterer.name}</a></li>`))}</ul>`
          }
        },
        {
          '@type': 'Question',
          'name': 'How do I get pricing, availability, and catering packages for food trucks near me?',
          'acceptedAnswer': {
            '@type': 'Answer',
            'text': '<p>If your event details are confirmed, and your budget meets the minimum (see above), you can get catering packages and quotes by submitting a catering request.</p> <p>Your request will be sent to food trucks in your area, and available trucks will submit a catering package with pricing and menu. From there you can choose the best catering package for you.</p> <p>You can learn more about food truck catering <a href=\'https://booking.helpscoutdocs.com/category/249-catering-events\'>here</a>.</p>'
          }
        },
        {
          '@type': 'Question',
          'name': 'Why should I use Roaming Hunger?',
          'acceptedAnswer': {
            '@type': 'Answer',
            'text': '<p>Roaming Hunger is the largest website for food trucks and mobile food businesses. Since 2009, we&rsquo;ve helped thousands of companies and individuals book food trucks for catering and events. Today, we have over 18,500 independent food trucks, trailers, carts, and pop-ups on our site.</p> <ul> <li> <p><strong>Variety</strong>&nbsp;- Our network consists of hundreds of local vendors in every major city, totaling 18,500+ nationally.</p> </li> <li> <p><strong>Save Time</strong>&nbsp;- Why have 100 conversations to find one food truck when you can have one conversation to find 100 food trucks?!</p> </li> <li> <p><strong>Speed</strong>&nbsp;- Whether you are ready to book one day or one month out from your event, we will provide every available food truck option.</p> </li> <li> <p><strong>Quality Options </strong>- All the food trucks we work with are gourmet, vetted, licensed, proven and reliable.</p> </li> <li> <p><strong>Service</strong> - Every catering is protected by our 100% Roaming Hunger Service Guarantee. If the caterer does not show, we&rsquo;ll get you a replacement caterer or refund 100% of your money.</p> </li> </ul>'
            }
        }
      ]
    },
    'additionalType': [
      'https://www.wikidata.org/wiki/Q3303463',
      'https://www.wikidata.org/wiki/Q6887072'
    ],
    'image': [
        'https://d3hbe0kmbam4a5.cloudfront.net/assets/static/media/nachos.bca57378.jpg',
        'https://d3hbe0kmbam4a5.cloudfront.net/assets/static/media/wraps.85b3c0eb.jpg',
        'https://d3hbe0kmbam4a5.cloudfront.net/assets/static/media/how-it-works-large.b331beb9.jpg',
        'https://d3hbe0kmbam4a5.cloudfront.net/assets/static/media/tacos.64c4e3ca.jpg',
        'https://d3hbe0kmbam4a5.cloudfront.net/assets/static/media/fries.349dba3f.jpg',
        'https://d3hbe0kmbam4a5.cloudfront.net/assets/static/media/ice-cream.5af9f7b7.jpg'
    ],
    'url': `https://roaminghunger.com/${params.citySlug}/food-truck-catering/`,
    'name': `${place.name} Food Truck Catering`,
    '@id': `https://roaminghunger.com/${params.citySlug}/food-truck-catering/#FoodService`,
    'provider': caterersQuery.data?.slice(0,2).map(c => ({
      '@type': 'Restaurant',
      url: `https://roaminghunger.com/${c.slug}/`,
      name: c.name,
      '@id': `https://roaminghunger.com/${c.slug}/#Restaurant`,
    }))
  })

  const howItWorksSteps = cityName => [
    {
      title: "Give Us Your Details",
      description: `
        Tell us when and where your ${cityName} event is,
        and what type of food truck catering you need.
      `
    },
    {
      title: "Choose Your Cuisine",
      description: "We'll send you a list of available food trucks with menus and pricing"
    },
    {
      title: "Get Ready to Dig In",
      description: "We’ll coordinate the logistics and support you through the day of the event."
    }
  ]

  // Skip indexing if the page is a minor city with less than 3 caterers
  const skipIndexing = place.type !== 'neighborhood' && !place?.major && !place?.market && (!caterersQuery.data || caterersQuery.data.length < 3)


  return (
    <Layout>
      <Helmet>
        <title>{page?.content?.meta_title || `Food Truck Catering - ${displayName} - Roaming Hunger`}</title>
        <meta name="description" content={page?.content?.meta_description || `Roaming Hunger is the place to turn for unique and memorable food truck catering in ${displayName}. Find and book your food truck today.`}></meta>
        <meta name="og:title" content={page?.content?.meta_title || `Food Truck Catering - ${displayName}`}></meta>
        <script type="application/ld+json">
          {googleMetaTags}
        </script>
        {skipIndexing ? (
          <meta name="robots" content="noindex" />
        ) : null }
      </Helmet>
      <LeadModal
        {...modalState}
      />
      <FoodTruckCatering
        title={page?.content?.heading || `${place.name} Food Truck Catering`}
        description={page?.content?.description || undefined}
        onButtonClick={openModal}
        jumpTarget="#save-time"
        imageUrl={page?.content?.image_url || undefined}
      />
      <TruckSlider
        place={place}
        caterers={caterersQuery.data}
        cityState={displayName}
        title={page?.content?.subtitle}
        description={page?.content?.food_trucks_description}
      />
      {place.major && (
        // TODO: Add spacing around TagList possibly using .container class
        // like How it Works section
        <TagList
          heading={`${place.name} Food Truck Catering Options`}
          place={place}
          subheading={
            `Looking for a specific type of food for your guests?
            Roaming Hunger has over ${caterersQuery.meta?.stats.total.count}
            food trucks, carts, and pop-ups in ${place.name} for you to choose from.
            Browse all the cuisines we have to offer below.`
          }
          baseUrl={place.type === 'region' ? `/food-trucks/${place.url_slug}` : `/catering/${place.url_slug}`}
        />
      )}
      <SaveTime
        id="save-time"
        jumpTarget="#make-everyone-happy"
        onButtonClick={openModal}
        heading="Hire Your Perfect Caterer"
        description={
          `Hire the right food truck for your ${place.name} event. From all the food trucks in ${place.name},
          we’ll get you available and reliable options based on your event details and budget.
          That gives you more time to salivate over the menu.`
        }
        buttonText="Save Time Now"
      />
      <MakeEveryoneHappy
        id="make-everyone-happy"
        jumpTarget="#sit-back-relax"
        onButtonClick={openModal}
        description={
          `Satisfy every taste bud by selecting the right cuisines and
          dietary restrictions for your ${place.name} event. Whether you want to
          choose from dozens of cuisine options or create a customized menu,
          you’ll get what your guests need.`
        }
      />
      <SitBackRelax
        id="sit-back-relax"
        jumpTarget="#why-work-with-us"
        onButtonClick={openModal}
        description={
          `Book your ${place.name} event with confidence knowing we have established
          relationships with the food trucks. We hold all funds until after
          your event, so we can guarantee you’ll get fantastic service every time.`
        }
        buttonText="Book a Food Truck"
      />
      <WhyWorkWithUs
        id="why-work-with-us"
        placeName={place.name}
        onButtonClick={openModal}
      />
      <RepeatRepeat
        id="repeat-repeat"
        jumpTarget="#catering-any-event"
        onButtonClick={openModal}
        description={
          `Ready to keep every event catering fresh and exciting? Whether for another special
          celebration, every weekday, or at multiple locations, Roaming Hunger makes it simple
          to book another tasty event. Being the host with the most in ${place.name} has never been easier.`
        }
      />
      <CateringForAnyEvent
        id="catering-any-event"
        placeSlug={place.url_slug}
        heading={
          `Food Truck Catering For Any ${displayName} Event`
        }
      />
      <HowItWorks
        onButtonClick={openModal}
        heading={
          `How to Book a ${place.name} Food Truck for Your Event`
        }
        items={howItWorksSteps(place.name)}
      />
      <HowItWorksLarge
        onButtonClick={openModal}
        heading={
          `How to Book a ${place.name} Food Truck for Your Event`
        }
        items={howItWorksSteps(place.name)}
      />
      <CateringReviews
        reviews={reviewsQuery.data}
        total={reviewsQuery.meta?.stats?.total?.count}
        average_rating={reviewsQuery.meta?.stats?.rating?.average}
        title={`Recent Customer Reviews in ${place.name}`}
        subheading={
          `Want to know what it’s like to work with us?
          Here is real feedback from recent customers in the ${place.name} area.`
        }
      />
      <Calculator
        id="calculator"
        onButtonClick={openModal}
        subheading={
          `Use our ${place.name} food truck catering cost calculator to see how much your
          catering could cost based on your eaters and the type of food you’re looking for.`
        }
      />
      {place.type === 'city' && (
        <FeaturedClients
          onButtonClick={openModal}
          city={place}
        />
      )}
      <FAQ city={place} cityState={displayName} caterers={caterersQuery.data} caterersCount={caterersQuery.meta?.stats.total.count}/>
    </Layout>
  )
}

function TruckSlider(props) {
  const { caterers, cityState, place, title, description } = props

  if (!caterers?.length) {
    return null
  }

  const VARIANT_KEY = 'test'
  const EXPERIMENT_KEY = '2024_4_city_catering_truck_cards'
  const variantKey = useFeatureFlagVariantKey(EXPERIMENT_KEY)
  const experimentEnabled = VARIANT_KEY === variantKey

  return (
    <div className="pt-16 pb-1 text-center container">
      <h2 className="font-platform leading-none px-20 md:px-0 text-4xl md:text-5xl text-navy mb-10">
        {title || `The Best ${place.name} Food Truck Catering`}
      </h2>
      <p className='font-light text-lg text-black leading-snug mb-5 pb-16'>
        {description || `Choosing food truck catering in ${cityState}, is a great way to transform any event into something
        special and memorable. Below are some of our favorite and most reliable food truck caterers.`}
      </p>
      { experimentEnabled ? (
        <CatererCarousel
          caterers={caterers}
        />
      ) : (
        <Slider
          slidesPerRow={4}
          rows={2}
          slidesToScroll={1}
          arrows={false}
          dots={false}
          lazyLoad="progressive"
          responsive={[
            {
              breakpoint: 768,
              settings: {
                dots: false,
                arrows: false,
                slidesPerRow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 414,
              settings: {
                dots: false,
                arrows: false,
                slidesPerRow: 2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {caterers.map(c => (
            <div key={c.id}>
              <div className={styles.truck_slide_item}>
                <a href={`/${c.slug}/`}>
                  <div className={styles.black} />
                </a>
                <div
                  className={[styles.truck_slide_item_image, 'w-full h-full flex items-center justify-center']}
                  style={{
                    backgroundImage: `url('${c.photo ? getResizedPhotoUrl(c.photo, 618, 160) : truckPic }')`
                  }}
                >
                  <a href={`/${c.slug}/`} className="absolute">
                    <span>{c.name}</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}

    </div>
  )
}

function FAQ({city, cityState, caterers, caterersCount}) {
  return (
    <div className="bg-navy">
      <div className="container p-6">
        <div className="flex w-full">
          <div className="w-full py-16">
            <h2 className="text-white text-4xl leading-none font-platform text-center">
              Frequently Asked Questions
            </h2>
            <div className="w-full md:w-4/5 mb-2 mt-6 m-auto">
              <h3 className="text-white text-3xl">How much does it cost to rent a food truck in {city.name}?</h3>
              <div className='text-white text-lg'>
                <p className="mt-1 font-light">
                  On average renting a food truck for catering in {city.name} costs ${city.catering_average_cost || "1,541"}, and costs typically range from $800-$2,500.
                </p>
                <p className="mt-1 font-light">
                  Cuisine affects the costs of your food truck catering more than any other factor. Here
                  are typical per-person prices along with average minimums.
                </p>
                <table className="w-full table-auto border-spacing-px border-collapse border border-navy-300 my-4">
                  <thead>
                    <tr>
                      <th className="text-left p-2 border border-navy-300">Cuisine</th>
                      <th className="text-left p-2 border border-navy-300">Average Price per Person</th>
                      <th className="text-left p-2 border border-navy-300">1-100 Eaters (Minimum)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-2 border border-navy-300 font-light">
                        <a href="/food-truck-catering/taco-truck-catering/" target="blank" className="text-blue-400">Taco Truck Catering</a>
                      </td>
                      <td className="p-2 border border-navy-300 font-light">$10-15</td>
                      <td className="p-2 border border-navy-300 font-light">$1,000</td>
                    </tr>
                    <tr>
                      <td className="p-2 border border-navy-300 font-light">
                        <a href="/food-truck-catering/burger-catering-truck/" target="blank" className="text-blue-400">Burger</a>, &nbsp;
                        <a href="/food-truck-catering/bbq-food-truck-catering/" target="blank" className="text-blue-400">BBQ</a>, & &nbsp;
                        <a href="/food-truck-catering/pizza-truck-catering/" target="blank" className="text-blue-400">Pizza Truck Catering</a>
                      </td>
                      <td className="p-2 border border-navy-300 font-light">$15-25</td>
                      <td className="p-2 border border-navy-300 font-light">$1,500</td>
                    </tr>
                    <tr>
                      <td className="p-2 border border-navy-300 font-light">Dessert Truck Catering</td>
                      <td className="p-2 border border-navy-300 font-light">$8-20</td>
                      <td className="p-2 border border-navy-300 font-light">$800</td>
                    </tr>
                    <tr>
                      <td className="p-2 border border-navy-300 font-light">Beverage Truck Catering</td>
                      <td className="p-2 border border-navy-300 font-light">$8-20</td>
                      <td className="p-2 border border-navy-300 font-light">$800</td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-1 font-light">
                  Use our &nbsp;
                  <a
                    href={`#calculator`}
                    className="text-blue-400 underline"
                  >
                    Food Truck Catering Cost Calculator
                  </a>
                </p>
                <p className="mt-1 font-light">
                  Adding drinks or dessert options (with a savory food truck) will increase the price per
                  person by $1-$5.
                </p>
                <p className="mt-1 font-light">
                  If you're having a smaller corporate event of 50 guests or less, you'll probably be affected by the
                  food truck catering minimums, and the price per person will increase.
                </p>
                <p className="mt-1 font-light">
                  Typical service times are 2-3 hours. Additional hours will increase total costs. Travel fees can
                  also affect pricing. Food trucks may charge a per-mile fee if your event is outside
                  their normal travel radius (20-30 miles).
                </p>
                <p className="mt-1 font-light">
                  This average cost is based on historical data from Roaming Hunger catering bookings
                  in the {city.name} area and does not include any taxes or fees.
                </p>
              </div>

              <h3 className="text-white text-3xl mt-6">What are the best food trucks in {cityState} for catering?</h3>
              <div className='text-white text-lg'>
                <p className="mt-1 font-light">
                  These are the food trucks that get booked the most for catering in {city.name}:
                </p>
                <ul className="pl-2 list-disc list-inside">
                  {caterers?.slice(0,5).map(caterer => (
                    <li key={caterer.id} className="font-light">
                      <a href={`/${caterer.slug}/`} target="blank" className="text-blue-400">{caterer.name}</a>
                    </li>
                  ))}
                </ul>
                <p className="mt-1 font-light">
                  We update this list every month based on new data.
                </p>
              </div>
              <h3 className="text-white text-3xl mt-6">How do I get pricing, availability, and catering packages for food trucks in {city.name}?</h3>
              <div className='text-white text-lg'>
                <p className="mt-1 font-light">
                  Get started by clicking any of the buttons on this page.
                </p>
                <p className="mt-1 font-light">
                  To get available options, you’ll need a confirmed date and location because food trucks get booked up every day.
                  Once you submit your event details, we’ll send your catering request to food trucks in your area.
                </p>
                <p className="mt-1 font-light">
                  Your request will be sent to food trucks near you in {city.name} and available trucks will submit a catering
                  package with pricing and menu. From there you can choose the{' '}
                  <a href={`https://roaminghunger.com/food-trucks/${city.url_slug}/`} className="text-blue-400">best {city.name} food truck</a> for you.
                </p>
              </div>
              <h3 className="text-white text-3xl mt-6">Why should I use Roaming Hunger to book a {city.name} food truck vs. finding one on my own?</h3>
              <div className='text-white text-lg'>
                <p className="mt-1 font-light">
                  Roaming Hunger is the largest website for quality food trucks and mobile food businesses.
                  Since 2009, we've helped thousands of companies and individuals book food trucks for catering and events.
                  Today, our site has over 21,000 independent food trucks, trailers, carts, and pop-ups.
                </p>
                <ol className="pl-6 list-disc list-outside">
                  <li className="font-light"><strong className="font-black">Variety</strong> - Our network consists of {caterersCount} {city.name} vendors, all of them offering different cuisines, menus and formats.</li>
                  <li className="font-light"><strong className="font-black">Save Time</strong> - Why have 100 conversations to find one food truck when you can have one conversation to find 100 food trucks?!</li>
                  <li className="font-light"><strong className="font-black">Local Expertise</strong> - Who do you choose once you get all your options? Use our team’s expertise to hire the best caterer for your event.</li>
                  <li className="font-light"><strong className="font-black">Speed</strong> - We will provide every available {city.name} food truck option if you are ready to book one day or one month out from your event.</li>
                  <li className="font-light"><strong className="font-black">Quality Options</strong> - All the food trucks we work with are gourmet, vetted, licensed, proven, and reliable.</li>
                  <li className="font-light">
                    <strong className="font-black">Service</strong> - Our{' '}
                    <a
                      href="https://roaminghunger.com/food-truck-catering/roaming-hunger-guarantee/"
                      className="text-blue-400">
                        100% Roaming Hunger Service Guarantee
                    </a>{' '}
                    protects every catering. If the caterer does not show up, we'll get you a replacement caterer or refund 100% of your money.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
