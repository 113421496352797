import React from 'react'
import wraps from 'assets/img/wraps.jpg'
import ImageSection from '../ImageSection'

export default function SitBackRelax(props) {
  const {
    className,
    imageUrl = wraps,
    heading = 'Sit Back and Relax',
    description = `
    Book your event with confidence knowing we have established relationships
    with the food trucks. We hold all funds until after your event, so we can
    guarantee you’ll get fantastic service every time.`,
    ...rest
  } = props

  return (
    <ImageSection
      imageUrl={imageUrl}
      className={[className ?? 'md:text-white']}
      heading={heading}
      description={description}
      {...rest}
    />
  )
}
