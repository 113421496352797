import React from 'react'
import logo from 'assets/svg/logo.svg'

export default function NavBar(props) {
  const { className, children } = props

  return (
    <div className={['sticky top-0 z-10 bg-blue', className]}>
      <div className="container py-2 flex items-center">
        <a href="/">
          <img
            src={logo}
            title="Roaming Hunger"
            width={52}
            className="mr-5"
          />
        </a>
        <div className="flex-1">
          {children}
        </div>
      </div>
    </div>
  )
}
