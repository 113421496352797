import React from 'react'
import extraStyles from './Input.module.css'

const theme = {
  div: null,
  label: 'label block mb-2',
  input: 'input-basic',
  error: 'text-sm text-red-600 mt-1',
}

export default function Input(props) {
  const {
    type,
    value,
    className,
    classNames,
    onChange,
    divClassName,
    divProps,
    label,
    labelProps,
    labelClassName,
    error,
    errorProps,
    icon,
    ...inputProps
  } = props

  const styles = { ...theme, ...classNames }

  let val = value
  if (onChange && !value && value !== 0) {
    val = ''
  }

  return (
    <div className={[styles.div, divClassName]} {...divProps}>
      {label && (
        <label className={[styles.label, labelClassName]} {...labelProps}>
          {label}
        </label>
      )}
      <div
        className={[
          'relative',
          icon && 'flex items-center',
        ]}
      >
        <input
          {...inputProps}
          className={[styles.input, className]}
          type={type}
          value={val}
          onChange={event => {
            let val = event.target.value || null

            if (val && type === 'number') {
              val = Number(val)
            }

            if (onChange) {
              onChange(val, event)
            }
          }}
        />
        {icon && (
          <div
            className={[
              extraStyles.icon,
              'absolute bg-gray-100 border-l border-gray-300 px-3',
              'flex items-center justify-center'
            ]}
          >
            {icon}
          </div>
        )}
      </div>
      {error && (
        <div className={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}

Input.theme = theme
