import React, { useRef } from 'react'
import { Autocomplete, useLoadScript } from '@react-google-maps/api'
import env from 'env'

const defaultFields = [
  'name',
  'place_id',
  'geometry',
  'address_components',
  'formatted_address',
]

export default function LocationAutoComplete(props) {
  const {
    onPlaceChanged,
    types = ['address'],
    libraries = ['places'],
    fields = defaultFields,
    children,
    ...config
  } = props

  const ref = useRef()
  const lib = useRef(libraries)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env.GOOGLE_API_KEY,
    libraries: lib.current,
  })

  if (loadError) {
    return <>{children}</>
  }

  if (!isLoaded) {
    return <>{children}</>
  }

  return (
    <Autocomplete
      {...config}
      children={children}
      types={types ? types : undefined}
      fields={fields}
      onLoad={autocomplete => {
        ref.current = autocomplete
      }}
      onPlaceChanged={() => {
        if (ref.current && onPlaceChanged) {
          const place = parsePlace(ref.current.getPlace())

          if (place) {
            onPlaceChanged(place)
          }
        }
      }}
    />
  )
}

function getFormatted(place) {
  const parts = []
  parts.push([place.street, place.street && place.unit_number].filter(Boolean).join(' '))
  parts.push(place.city_name)
  parts.push([place.region_code || place.region_name, place.zip].filter(Boolean).join(' '))
  parts.push(place.country_code !== 'US' ? (place.country_name || place.country_code) : null)
  return parts.filter(Boolean).join(', ')
}

function parsePlace(data) {
  if (!data.formatted_address) {
    return null
  }

  const result = {
    street: null,
    unit_number: null,
    city_name: null,
    region_name: null,
    region_code: null,
    country_name: null,
    country_code: null,
    zip: null,
    location: null,
    formatted: null,
  }

  let formatted = data.formatted_address
  let street_name
  let street_number
  let neighborhood

  data.address_components.forEach(component => {
    component.types.forEach(type => {
      switch (type) {
        case 'route':
          street_name = component.long_name
        break
        case 'street_number':
          street_number = component.long_name
        break
        case 'subpremise':
          result.unit_number = component.long_name
        break
        case 'country':
          result.country_name = component.long_name
          result.country_code = component.short_name
        break
        case 'administrative_area_level_1':
          result.region_name = component.long_name
          result.region_code = component.short_name
        break
        case 'locality':
          result.city_name = component.long_name
        break
        case 'neighborhood':
          neighborhood = component.long_name
        break
        case 'postal_code':
          result.zip = component.long_name
        break
      }
    })
  })

  result.street = [
    street_number,
    street_name,
  ].filter(Boolean).join(' ') || null

  if (neighborhood && (!result.city_name || formatted.includes(neighborhood))) {
    result.city_name = neighborhood
  }

  result.formatted = getFormatted(result)

  if (data?.geometry?.location) {
    result.location = {
      lng: data.geometry.location.lng(),
      lat: data.geometry.location.lat(),
    }
  }

  return result
}
