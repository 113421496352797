import React from 'react'
import { GoogleMap as Map, useLoadScript } from '@react-google-maps/api'
import env from 'env'

export default function GoogleMap(props) {
  const {
    channel,
    libraries,
    preventGoogleFontsLoading,
    region,
    version,
    children,
    markers,
    zoom = 7,
    ...config
  } = props

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env.GOOGLE_API_KEY,
    channel,
    libraries,
    preventGoogleFontsLoading,
    region,
    version,
  })

  if (loadError) {
    return <div>Map is not available.</div>
  }

  if (!isLoaded) {
    return null
  }

  return (
    <Map
      zoom={zoom}
      mapContainerStyle={{
        width: '100%',
        height: '100%'
      }}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.RIGHT_TOP,
        }
      }}
      {...config}
    >
      {children}
      {markers}
    </Map>
  )
}
