import React from 'react'
import fries from 'assets/img/fries.jpg'
import ImageSection from '../ImageSection'

export default function SaveTime(props) {
  const {
    className,
    imageUrl = fries,
    heading = 'Save Time',
    description = `Compare hundreds of food trucks in one place. We negotiate the best rate,
    coordinate logistics, and make sure the food truck shows up with
    everything you need. That gives you more time to salivate over the menu.`,
    ...rest
  } = props

  return (
    <ImageSection
      imageUrl={imageUrl}
      className={[className ?? 'md:text-black']}
      heading={heading}
      description={description}
      {...rest}
    />
  )
}
