import React from 'react'
import styles from './CuisineTypes.module.css'
import config from 'app/pages/cuisine/config'
import { useParams } from 'react-router-dom'

export default function CuisineTypes(props) {
  const {
    heading = 'Explore Food Truck Catering Cuisines',
    description,
    showFooter = false,
    baseUrl = '/',
    className,
    staticHtml = true,
    ...rest
  } = props

  return (
    <section
      {...rest}
      className={[
        className,
        'text-center pt-16 pb-20',
      ]}
    >
      <div className={[styles.container]}>
        <h2 className="font-platform text-navy text-3xl lg:text-4xl">
          {heading}
        </h2>
        <p className="text-lg text-navy font-light leading-none my-5">
          {description || (
            <span>We have over <b>150 cuisine</b> options to choose from. Here are our top ones.</span>
          )}
        </p>
      </div>
      <div className={['my-10', styles.wrapper]}>
        <Type baseUrl={baseUrl} />
      </div>
      {showFooter && (
        <p className="text-lg text-navy font-light leading-none my-5">
          Have no cuisine preference? &nbsp;<b>Check out our general <a href="/food-truck-catering/" className="underline">Food Truck Catering</a> page.</b>
        </p>
      )}
    </section>
  )
}

function Type({ baseUrl }) {
  const params = useParams()

  return (
    <ul
      className="container grid grid-cols-1 md:grid-cols-3 gap-5"
    >
      {config.pages.filter(type => type.slug !== params.cuisine).map((type, index) => (
        <li
          key={index}
          className={[
            styles.box,
          ]}
          style={{
            backgroundImage: `url(${type.image})`
          }}
        >
          <a
            href={baseUrl + '/' + type.slug + '/'}
            className={styles.cover}
          >
            <h4>{type.title}</h4>
          </a>
        </li>
      ))}
    </ul>
  )
}
