import React from 'react'
import styles from './HowItWorksLarge.module.css'
import howItWorksLarge from 'assets/img/how-it-works-large.jpg'
import LazyImg from 'app/theme/LazyImage'

const defaultItems = [
  { title: 'Give Us Details', description: 'Tell us when & where you want food truck catering.' },
  { title: 'Choose Your Cuisine', description: 'We\'ll send you a list of available food trucks.' },
  { title: 'Get Ready to Dig In', description: 'We\'ll negotiate the best rate and coordinate the logistics.' },
]

export default function HowItWorksLarge(props) {
  const {
    heading = 'How It Works',
    className,
    buttonText = 'Get Started',
    buttonUrl,
    onButtonClick,
    showMobile = false,
    items = defaultItems,
    image = howItWorksLarge,
    ...rest
  } = props

  var sectionClass = 'text-navy pt-16 pb-20 lg:block'
  if (!showMobile) {
    sectionClass += " hidden"
  }

  return (
    <section
      {...rest}
      className={[
        className,
        sectionClass,
      ]}
    >
      <div className="container">
        <h2 className="font-platform text-center mb-10 text-3xl lg:text-4xl">
          {heading}
        </h2>
        <div className="relative md:flex md:items-center md:justify-end">
          <div className='flex flex-auto flex-grow w-full md:w-1/2 py-4 md:p-4 items-center justify-center'>
            <LazyImg src={image} className="block max-w-full" />
          </div>

          <div className='flex-grow'>
            {items.map((item, index) => (
              <div className={['flex', (index !== 0 && 'mt-6')]} key={index}>
                <div className={[styles.circle, 'm-auto mb-2 border-2 border-navy rounded-full text-center']}>
                  {index + 1}
                </div>
                <div className="flex-grow ml-6">
                  <h3 className='text-3xl leading-tight'>{item.title}</h3>
                  <p className='text-xl font-light'>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          {onButtonClick ? (
            <button
              type="button"
              children={buttonText}
              className='btn btn-green text-2xl font-platform px-10 py-3 mt-12'
              onClick={onButtonClick}
            />
          ) : buttonUrl ? (
            <a
              href={buttonUrl}
              children={buttonText}
              className='btn btn-green text-2xl font-platform px-10 py-3 mt-12'
            />
          ) : null}
        </div>
      </div>
    </section>
  )
}
