import bbqHeader from 'assets/img/cuisine/bbq-header.jpg'
import tacoHeader from 'assets/img/cuisine/taco-header.jpg'
import pizzaHeader from 'assets/img/cuisine/pizza-header.jpg'
import burgerHeader from 'assets/img/cuisine/burger-header.jpg'
import iceCreamHeader from 'assets/img/cuisine/ice-cream-header.png'

import bbqHowItWorks from 'assets/img/cuisine/bbq-how-it-works.jpg'
import tacoHowItWorks from 'assets/img/cuisine/taco-how-it-works.jpg'
import burgerHowItWorks from 'assets/img/cuisine/burger-how-it-works-1.jpg'
import pizzaHowItWorks from 'assets/img/cuisine/pizza-how-it-works.jpg'
import iceCreamHowItWorks from 'assets/img/cuisine/ice-cream-how-it-works.png'

import bbqText1 from 'assets/img/cuisine/bbq-text-1.jpg'
import bbqText2 from 'assets/img/cuisine/bbq-text-2.jpg'
import bbqText3 from 'assets/img/cuisine/bbq-text-3.jpg'
import bbqText4 from 'assets/img/cuisine/bbq-text-4.jpg'

import taco1 from 'assets/img/cuisine/taco-1.jpg'
import taco2 from 'assets/img/cuisine/taco-2.jpg'
import taco3 from 'assets/img/cuisine/taco-3.jpg'
import taco4 from 'assets/img/cuisine/taco-4.jpg'

import burger1 from 'assets/img/cuisine/burger-1.jpg'
import burger2 from 'assets/img/cuisine/burger-2.jpg'
import burger3 from 'assets/img/cuisine/burger-3.jpg'
import burger4 from 'assets/img/cuisine/burger-4.jpg'

import pizza1 from 'assets/img/cuisine/pizza-1.jpg'
import pizza2 from 'assets/img/cuisine/pizza-2.jpg'
import pizza3 from 'assets/img/cuisine/pizza-3.jpg'
import pizza4 from 'assets/img/cuisine/pizza-4-darker.jpg'

import iceCream1 from 'assets/img/cuisine/ice-cream-1.png'
import iceCream2 from 'assets/img/cuisine/ice-cream-2.png'
import iceCream3 from 'assets/img/cuisine/ice-cream-3.png'
import iceCream4 from 'assets/img/cuisine/ice-cream-4.png'

export default {
  pages: [
    {
      slug: 'bbq-food-truck-catering',
      tag_slug: 'bbq',
      seo_title: 'BBQ Food Truck Catering',
      seo_description:
        'Book a bbq truck for your next catered event with Roaming Hunger. You give us the details and we find the best bbq food truck caterers near you.',
      title: 'BBQ Truck Catering',
      description_term: 'gourmet BBQ food truck',
      image: bbqHeader,
      why_work_with_us_subheading:
        'Every BBQ truck catering is protected by the 100% Roaming Hunger Service Guarantee.',
      how_it_works_image: bbqHowItWorks,
      how_it_works: [
        {
          title: 'Give Us Details',
          description: 'Tell us when & where you want food truck catering.',
        },
        {
          title: 'Choose Your Package',
          description:
            "We'll send you a list of available BBQ trucks, trailers, and carts.",
        },
        {
          title: 'Get Ready to Dig In',
          description:
            "We'll negotiate the best rate and coordinate the logistics.",
        },
      ],
      slides: {
        save_time: {
          title: 'Save Time',
          description:
            'Compare all the bbq trucks and caterers near you in one place. We negotiate the best rate, coordinate logistics, and make sure the truck shows up with everything you need. That gives you more time to salivate over which meats, toppings, and sides you want.',
          image: bbqText1,
          textColor: 'md:text-white',
        },
        make_everyone_happy: {
          title: 'Make Everyone Happy',
          description:
            'BBQ takes any event to a whole new level. Let our mobile caterers prepare the entire meal before your very eyes.  We guarantee every tastebud at the party will be satisfied.',
          image: bbqText2,
        },
        sit_back_relax: {
          title: 'Relax & Enjoy the BBQ',
          description:
            'Book your event with confidence knowing we have established relationships with our food truck caterers. We hold all funds until after your event, so we can guarantee you’ll get fantastic service every time.',
          image: bbqText3,
          textColor: 'md:text-black',
        },
        repeat: {
          title: 'RepEAT, RepEAT',
          description:
            'Whether for a special celebration or every week, Roaming Hunger makes it simple to book another caterer (BBQ or otherwise) and feed your crowd. Being the host with the most has never been easier.',
          image: bbqText4,
        },
      },
      calculator: {
        heading: 'How Much Does BBQ Truck Catering Cost?',
        subheading:
          'Use our catering cost calculator below to estimate how much it costs to rent a BBQ truck in your city.',
        minimum: 1500,
        price_per_person: 15,
      },
      faq: [
        {
          question: 'How much does a BBQ truck for a party or event cost?',
          answer: `<p>A safe estimate is $15-25 per person, with a minimum headcount of 100 eaters for every 2 hours of catering.</p>
            <p>If you have less than 100 eaters, that’s ok, but you can expect the per person cost to increase. On average the minimum cost for BBQ catering is $1,500-$2,500.</p>`,
        },
        {
          question:
            'Where can I see different catering packages for BBQ trucks near me?',
            answer: `<p>If your event details are confirmed and your budget meets the minimum (see above), you can get catering packages and quotes by submitting a catering request.</p>
            <p>Your request will be sent to food trucks in your area and available trucks will submit a package and quote. From there you can choose the best vendor for you.</p>
            <p>You can learn more about food truck catering <a href="https://booking.helpscoutdocs.com/category/249-catering-events" target="_blank">here</a>.</p>`,
        },
        {
          question: 'Why should I use Roaming Hunger?',
          answer: `<p>Roaming Hunger is the largest website for food trucks and mobile food businesses. Since 2009, we’ve helped thousands of companies and individuals book food trucks for catering and events. Today, we have over 18,500 independent food trucks, trailers, carts, and pop-ups on our site.</p>
            <ol>
            <li><strong>Variety</strong> - Our network consists of hundreds of local vendors in every major city, totaling 18,000+ nationally.</li>
            <li><strong>Save Time</strong> - Why have 100 conversations to find one food truck when you can have one conversation to find 100 food trucks?!</li>
            <li><strong>Speed</strong> - Whether you are ready to book one day or one month out from your event, we will provide every available food truck option.</li>
            <li><strong>Quality Options</strong> - All the food trucks we work with are gourmet, vetted, licensed, proven and reliable.</li>
            <li><strong>Service</strong> - Every catering is protected by our 100% Roaming Hunger Service Guarantee. If the caterer does not show, we’ll get you a replacement caterer or refund 100% of your money.</li>
            </ol>`,
        }
      ],
      occasions: {
        title: 'BBQ Food Truck Catering For Any Event',
        description:
          'Need to feed hundreds of eaters? Rent a truck (or two) that can handle the crowd. Having an intimate party? Book a cart or tent that can fit in smaller spaces and backyards. No matter the occasion, you’ll find the right mobile caterer for your needs.',
      },
    },
    {
      slug: 'taco-truck-catering',
      tag_slug: 'tacos',
      seo_title: 'Taco Truck Catering',
      seo_description:
        'Book a taco truck or cart for your next catered event with Roaming Hunger. You give us the details and we find the caterer near you.',
      title: 'Taco Truck Catering',
      description_term: 'taco truck',
      image: tacoHeader,
      why_work_with_us_subheading:
        'Every taco truck catering is protected by the 100% Roaming Hunger Service Guarantee.',
      how_it_works_image: tacoHowItWorks,
      how_it_works: [
        {
          title: 'Give Us Details',
          description: 'Tell us when & where you want taco truck catering.',
        },
        {
          title: 'Choose Your Package',
          description:
            "We'll send you a list of available trucks, trailers, and carts.",
        },
        {
          title: 'Get Ready to Dig In',
          description:
            "We'll negotiate the best rate and coordinate the logistics.",
        },
      ],
      slides: {
        save_time: {
          title: 'Save Time',
          description:
            'Compare all the taco trucks and caterers near you in one place. We negotiate the best rate, coordinate logistics, and make sure the truck shows up with everything you need. That gives you more time to salivate over which toppings, and sides you want.',
          image: taco1,
          textColor: 'md:text-white',
        },
        make_everyone_happy: {
          title: 'Make Everyone Happy',
          description:
            'Tacos are one of the most diverse cuisines in the world. From traditional Mexican to Asian Fusion, there are options for vegetarians, vegans, and meat lovers alike. Choose from thousands of cuisine options and let guests customize individual orders during your event. No matter the dietary requirements, every tastebud will be satisfied.',
          image: taco2,
          textColor: 'md:text-black',
        },
        sit_back_relax: {
          title: 'Relax & Enjoy the Tacos',
          description:
            'Book your event with confidence knowing we have established relationships with our taco trucks. We hold all funds until after your event, so we can guarantee you’ll get fantastic service every time.',
          image: taco3,
        },
        repeat: {
          title: 'RepEAT, RepEAT',
          description:
            'Want to launch a Taco Tuesday lunch program? Whether for a special celebration or every week, Roaming Hunger makes it simple to book another caterer and feed your crowd. Being the host with the most has never been easier.',
          image: taco4,
        },
      },
      calculator: {
        heading: 'How Much Does a Taco Truck Rental Cost?',
        subheading:
          'Use our catering cost calculator below to estimate how much it costs to rent a taco truck in your city.',
        minimum: 1000,
        price_per_person: 10,
      },
      faq: [
        {
          question: 'How much does a taco truck for a party or event cost?',
          answer: `<p>A safe estimate is $10-15 per person, with a minimum headcount of 100 eaters for every 2 hours of catering.</p>
            <p>If you have less than 100 eaters, that’s ok, but you can expect the per person cost to increase. On average the minimum cost for taco truck catering is $1,000-$1,500.</p>`,
        },
        {
          question:
            'Where can I see different catering packages for taco trucks near me?',
          answer: `<p>If your event details are confirmed and your budget meets the minimum (see above), you can get catering packages and quotes by submitting a catering request.</p>
            <p>Your request will be sent to food trucks in your area and available trucks will submit a package and quote. From there you can choose the best vendor for you.</p>
            <p>You can learn more about food truck catering <a href="https://booking.helpscoutdocs.com/category/249-catering-events" target="_blank">here</a>.</p>`,
        },
        {
          question: 'Why should I use Roaming Hunger?',
          answer: `<p>Roaming Hunger is the largest website for food trucks and mobile food businesses. Since 2009, we’ve helped thousands of companies and individuals book food trucks for catering and events. Today, we have over 18,500 independent food trucks, trailers, carts, and pop-ups on our site.</p>
            <ol>
            <li><strong>Variety</strong> - Our network consists of hundreds of local vendors in every major city, totaling 18,000+ nationally.</li>
            <li><strong>Save Time</strong> - Why have 100 conversations to find one food truck when you can have one conversation to find 100 food trucks?!</li>
            <li><strong>Speed</strong> - Whether you are ready to book one day or one month out from your event, we will provide every available food truck option.</li>
            <li><strong>Quality Options</strong> - All the food trucks we work with are gourmet, vetted, licensed, proven and reliable.</li>
            <li><strong>Service</strong> - Every catering is protected by our 100% Roaming Hunger Service Guarantee. If the caterer does not show, we’ll get you a replacement caterer or refund 100% of your money.</li>
            </ol>`,
        },
      ],
      occasions: {
        title: 'Taco Truck Catering For Any Event',
        description:
          'Need to feed hundreds of eaters? Rent a taco truck (or two) that can handle the crowd. Having an intimate party? Book a taco cart that can fit in small spaces and backyards. No matter the occasion, you’ll find the right caterer for your needs.',
      },
    },
    {
      slug: 'burger-catering-truck',
      tag_slug: 'burgers',
      seo_title: 'Burger Truck Catering',
      seo_description:
        'Book a burger catering truck for your next catered event with Roaming Hunger. You give us the details and we find the best burger food trucks near you.',
      title: 'Burger Truck Catering',
      description_term: 'gourmet burger catering truck',
      image: burgerHeader,
      why_work_with_us_subheading:
        'Every burger truck catering is protected by the 100% Roaming Hunger Service Guarantee.',
      how_it_works_image: burgerHowItWorks,
      how_it_works: [
        {
          title: 'Give Us Details',
          description: 'Tell us when & where you want the catering truck.',
        },
        {
          title: 'Choose Your Package',
          description:
            "We'll send you a list of available burger trucks, trailers, and carts.",
        },
        {
          title: 'Get Ready to Dig In',
          description:
            "We'll negotiate the best rate and coordinate the logistics.",
        },
      ],
      slides: {
        save_time: {
          title: 'Save Time',
          description:
            'Compare all the burger trucks and caterers near you in one place. We negotiate the best rate, coordinate logistics, and make sure the food truck shows up with everything you need. That gives you more time to salivate over the menu.',
          image: burger1,
          textColor: 'md:text-white',
        },
        make_everyone_happy: {
          title: 'Make Everyone Happy',
          description:
            'Want fries, chips, and drinks with that? No problem. And no matter the dietary restrictions, we’ll make sure every tastebud at the party is satisfied. Choose from thousands of cuisine options and let guests customize individual orders during your event. No matter the dietary requirements, every tastebud will be satisfied.',
          image: burger2,
        },
        sit_back_relax: {
          title: 'Relax & Enjoy the Burgers',
          description:
            'Book your event with confidence knowing we have established relationships with our food truck caterers. We hold all funds until after your event, so we can guarantee you’ll get fantastic service every time.',
          image: burger3,
          textColor: 'md:text-black',
        },
        repeat: {
          title: 'RepEAT, RepEAT',
          description:
            'Whether for a special celebration or every week, Roaming Hunger makes it simple to book another caterer (burgers or otherwise) and feed your crowd. Being the host with the most has never been easier.',
          image: burger4,
        },
      },
      calculator: {
        heading: 'How Much Does a Burger Catering Truck Cost?',
        subheading:
          'Use our catering cost calculator below to estimate how much it costs to rent a burger truck in your city.',
        minimum: 1500,
        price_per_person: 15,
      },
      faq: [
        {
          question: 'How much does a burger truck for a party or event cost?',
          answer: `<p>A safe estimate is $15-25 per person, with a minimum headcount of 100 eaters for every 2 hours of catering.</p>
            <p>If you have less than 100 eaters, that’s ok, but you can expect the per person cost to increase. On average the minimum cost for burger truck catering is $1,500-$2,500.</p>`,
        },
        {
          question:
            'Where can I see different catering packages for burger trucks near me?',
            answer: `<p>If your event details are confirmed and your budget meets the minimum (see above), you can get catering packages and quotes by submitting a catering request.</p>
            <p>Your request will be sent to food trucks in your area and available trucks will submit a package and quote. From there you can choose the best vendor for you.</p>
            <p>You can learn more about food truck catering <a href="https://booking.helpscoutdocs.com/category/249-catering-events" target="_blank">here</a>.</p>`,
        },
        {
          question: 'Why should I use Roaming Hunger?',
          answer: `<p>Roaming Hunger is the largest website for food trucks and mobile food businesses. Since 2009, we’ve helped thousands of companies and individuals book food trucks for catering and events. Today, we have over 18,500 independent food trucks, trailers, carts, and pop-ups on our site.</p>
            <ol>
            <li><strong>Variety</strong> - Our network consists of hundreds of local vendors in every major city, totaling 18,000+ nationally.</li>
            <li><strong>Save Time</strong> - Why have 100 conversations to find one food truck when you can have one conversation to find 100 food trucks?!</li>
            <li><strong>Speed</strong> - Whether you are ready to book one day or one month out from your event, we will provide every available food truck option.</li>
            <li><strong>Quality Options</strong> - All the food trucks we work with are gourmet, vetted, licensed, proven and reliable.</li>
            <li><strong>Service</strong> - Every catering is protected by our 100% Roaming Hunger Service Guarantee. If the caterer does not show, we’ll get you a replacement caterer or refund 100% of your money.</li>
            </ol>`,
        },
      ],
      occasions: {
        title: 'Burger Truck Catering For Any Event',
        description:
          'Need to feed hundreds of eaters? Rent a burger truck (or two) that can handle the crowd. Having an intimate party? Book a cart or tent that can fit in smaller spaces and backyards. No matter the occasion, you’ll find the right mobile caterer for your needs.',
      },
    },
    {
      slug: 'pizza-truck-catering',
      tag_slug: 'pizza',
      seo_title: 'Pizza Truck Catering',
      seo_description:
        'Book a pizza truck for your next catered event with Roaming Hunger. You give us the details and we find the best pizza food trucks near you.',
      title: 'Pizza Truck Catering',
      description_term: 'gourmet pizza truck',
      image: pizzaHeader,
      why_work_with_us_subheading:
        'Every pizza truck catering is protected by the 100% Roaming Hunger Service Guarantee.',
      how_it_works_image: pizzaHowItWorks,
      how_it_works: [
        {
          title: 'Give Us Details',
          description: 'Tell us when & where you want the catering truck.',
        },
        {
          title: 'Choose Your Package',
          description:
            "We'll send you a list of available pizza trucks, trailers, and carts.",
        },
        {
          title: 'Get Ready to Dig In',
          description:
            "We'll negotiate the best rate and coordinate the logistics.",
        },
      ],
      slides: {
        save_time: {
          title: 'Save Time',
          description:
            'Compare all the pizza trucks and caterers near you in one place. We negotiate the best rate, coordinate logistics, and make sure the food truck shows up with everything you need. That gives you more time to salivate over the menu.',
          image: pizza1,
          textColor: 'md:text-white',
        },
        make_everyone_happy: {
          title: 'Make Everyone Happy',
          description:
            'There’s nothing like a hot, fresh pie coming straight out of the oven. And whether you need vegetarian and gluten-free options, every tastebud will be satisfied. Choose from thousands of cuisine options and let guests customize individual orders during your event. No matter the dietary requirements, every tastebud will be satisfied.',
          image: pizza2,
          textColor: 'md:text-black',
        },
        sit_back_relax: {
          title: 'Relax & Enjoy Your Pizza',
          description:
            'Book your event with confidence knowing we have established relationships with our food truck caterers. We hold all funds until after your event, so we can guarantee you’ll get fantastic service every time.',
          image: pizza3,
        },
        repeat: {
          title: 'RepEAT, RepEAT',
          description:
            'Whether for a special celebration or every week, Roaming Hunger makes it simple to book another caterer (pizza or otherwise) and feed your crowd. Being the host with the most has never been easier.',
          image: pizza4,
        },
      },
      calculator: {
        heading: 'How Much Does Pizza Truck Catering Cost?',
        subheading:
          'Use our catering cost calculator below to estimate how much it costs to rent a pizza truck in your city.',
        minimum: 1500,
        price_per_person: 15,
      },
      faq: [
        {
          question: 'How much does a pizza truck for a party or event cost?',
          answer: `<p>A safe estimate is $15-25 per person, with a minimum headcount of 100 eaters for every 2 hours of catering.</p>
          <p>If you have less than 100 eaters, that’s ok, but you can expect the per person cost to increase. On average the minimum cost for pizza truck catering is $1,500-$2,500.</p>`,
        },
        {
          question:
            'Where can I see different catering packages for pizza trucks near me?',
            answer: `<p>If your event details are confirmed and your budget meets the minimum (see above), you can get catering packages and quotes by submitting a catering request.</p>
            <p>Your request will be sent to food trucks in your area and available trucks will submit a package and quote. From there you can choose the best vendor for you.</p>
            <p>You can learn more about food truck catering <a href="https://booking.helpscoutdocs.com/category/249-catering-events" target="_blank">here</a>.</p>`,
        },
        {
          question: 'Why should I use Roaming Hunger?',
          answer: `<p>Roaming Hunger is the largest website for food trucks and mobile food businesses. Since 2009, we’ve helped thousands of companies and individuals book food trucks for catering and events. Today, we have over 18,500 independent food trucks, trailers, carts, and pop-ups on our site.</p>
            <ol>
            <li><strong>Variety</strong> - Our network consists of hundreds of local vendors in every major city, totaling 18,000+ nationally.</li>
            <li><strong>Save Time</strong> - Why have 100 conversations to find one food truck when you can have one conversation to find 100 food trucks?!</li>
            <li><strong>Speed</strong> - Whether you are ready to book one day or one month out from your event, we will provide every available food truck option.</li>
            <li><strong>Quality Options</strong> - All the food trucks we work with are gourmet, vetted, licensed, proven and reliable.</li>
            <li><strong>Service</strong> - Every catering is protected by our 100% Roaming Hunger Service Guarantee. If the caterer does not show, we’ll get you a replacement caterer or refund 100% of your money.</li>
            </ol>`,
        },
      ],
      occasions: {
        title: 'Pizza Truck Catering For Any Event',
        description:
          'Need to feed hundreds of eaters? Rent a pizza truck (or two) that can handle the crowd. Having an intimate party? Book a cart or tent that can fit in smaller spaces and backyards. No matter the occasion, you’ll find the right mobile caterer for your needs.',
      },
    },
    {
      slug: 'ice-cream-truck-catering',
      tag_slug: 'ice-cream',
      seo_title: 'Ice Cream Truck Rental and Catering',
      seo_description:
        'Rent an ice cream truck for your next catered event with Roaming Hunger. You give us the details and we find the best ice cream truck caterers near you.',
      title: 'Ice Cream Truck Catering',
      description_term: 'ice cream truck',
      image: iceCreamHeader,
      why_work_with_us_subheading:
        'Every Ice Cream truck catering is protected by the 100% Roaming Hunger Service Guarantee.',
      how_it_works_image: iceCreamHowItWorks,
      how_it_works: [
        {
          title: 'Give Us Details',
          description: 'Tell us when & where you want the food truck catering.',
        },
        {
          title: 'Choose Your Package',
          description:
            "We'll send you a list of available Ice Cream trucks, trailers, and carts.",
        },
        {
          title: 'Get Ready to Dig In',
          description:
            "We'll negotiate the best rate and coordinate the logistics.",
        },
      ],
      slides: {
        save_time: {
          title: 'Save Time',
          description:
            'Compare all the ice cream trucks and caterers near you in one place. We negotiate the best rate, coordinate logistics, and make sure the truck shows up with everything you need. That gives you more time to salivate over which flavors, toppings, and sides you want.',
          image: iceCream1,
          textColor: 'md:text-white',
        },
        make_everyone_happy: {
          title: 'Make Everyone Happy',
          description:
            'Ice cream makes any event a lot more fun and delicious. Rent an ice cream truck for your party to cool off your guests. We guarantee every tastebud will be satisfied.',
          image: iceCream2,
          textColor: 'md:text-black',
        },
        sit_back_relax: {
          title: 'Relax & Enjoy the Ice Cream',
          description:
            'Book your event with confidence knowing we have established relationships with our ice cream truck caterers. We hold all funds until after your event, so we can guarantee you’ll get fantastic service every time.',
          image: iceCream3,
        },
        repeat: {
          title: 'RepEAT, RepEAT',
          description:
            'Whether for a special celebration or every week, Roaming Hunger makes it simple to book another caterer (Ice Cream or otherwise) and feed your crowd. Being the host with the most has never been easier.',
          image: iceCream4,
        },
      },
      calculator: {
        heading: 'How Much Does Ice Cream Truck Catering Cost?',
        subheading:
          'Use our catering cost calculator below to estimate how much it costs to rent an ice cream truck in your city.',
        minimum: 800,
        price_per_person: 8,
      },
      faq: [
        {
          question: 'How much does an ice cream truck rental for catering cost?',
          answer: `<p>A safe estimate for an ice cream truck is $8-20 per person, with a minimum headcount of 100 eaters for every 2 hours of catering.</p>
          <p>If you have less than 100 eaters, that’s ok, but you can expect the per person cost to increase. On average the minimum cost for ice cream truck catering is $800.</p>
          <p>An ice cream cart will cost less. Usually $5-$10 per person, with a rental minimum of about $300-$600.</p>`,
        },
        {
          question:
            'Where can I see different catering packages for ice cream trucks near me?',
          answer: `<p>If your event details are confirmed and your budget meets the minimum (see above), you can get catering packages and quotes by submitting a catering request.</p>
          <p>Your request will be sent to ice cream trucks in your area and available trucks will submit a package and quote. From there you can choose the best vendor for you.</p>
          <p>You can learn more about how food truck catering works <a href=\"https://booking.helpscoutdocs.com/category/249-catering-events\">here</a>.</p>`
        },
        {
          question: 'Why should I use Roaming Hunger?',
          answer: `<p>Roaming Hunger is the largest website for food trucks and mobile food businesses. Since 2009, we’ve helped thousands of companies and individuals book food trucks for catering and events. Today, we have over 18,500 independent food trucks, trailers, carts, and pop-ups on our site.</p>
            <ol>
            <li><strong>Variety</strong> - Our network consists of hundreds of local vendors in every major city, totaling 18,000+ nationally.</li>
            <li><strong>Save Time</strong> - Why have 100 conversations to find one food truck when you can have one conversation to find 100 food trucks?!</li>
            <li><strong>Speed</strong> - Whether you are ready to book one day or one month out from your event, we will provide every available food truck option.</li>
            <li><strong>Quality Options</strong> - All the food trucks we work with are gourmet, vetted, licensed, proven and reliable.</li>
            <li><strong>Service</strong> - Every catering is protected by our 100% Roaming Hunger Service Guarantee. If the caterer does not show, we’ll get you a replacement caterer or refund 100% of your money.</li>
            </ol>`,
        },
      ],
      occasions: {
        title: 'Ice Cream Truck Catering For Any Event',
        description:
          'Need to feed hundreds of eaters? Rent an Ice Cream truck (or two) that can handle the crowd. Having an intimate party? Book a cart or tent that can fit in smaller spaces and backyards. No matter the occasion, you’ll find the right mobile caterer for your needs.',
      },
    },
  ],
}
