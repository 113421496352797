import React from 'react'
import BackgroundCover from './BackgroundCover'
import JumpLinkArrow from './JumpLinkArrow'
import styles from './ImageHero.module.css'

export default function ImageHero(props) {
  const {
    imageUrl,
    className,
    jumpTarget,
    title,
    description,
    buttonText = 'Get Started',
    buttonUrl,
    nextSectionAltText,
    onButtonClick,
    ...rest
  } = props

  return (
    <BackgroundCover
      {...rest}
      imageUrl={imageUrl}
      className={[
        'relative text-white text-center px-5',
        styles.container,
        className,
      ]}
    >
      <h1 className="text-white font-platform leading-none text-5xl lg:text-6xl mb-2">
        {title}
      </h1>
      <div className="leading-tight mb-6 text-2xl md:text-3xl font-light max-w-6xl">
        {description}
      </div>
      {onButtonClick ? (
        <button
          onClick={onButtonClick}
          children={buttonText}
          data-test-id="leadStartButton"
          className="btn btn-green font-platform text-2xl px-10 py-2 shadow"
        />
      ) : buttonUrl ? (
        <a
          href={buttonUrl}
          children={buttonText}
          className="btn btn-green font-platform text-2xl px-10 py-2 shadow"
        />
      ) : null}
      {jumpTarget && (
        <JumpLinkArrow
          alt={nextSectionAltText}
          target={jumpTarget}
          className="absolute bottom-0 mb-10"
        />
      )}
    </BackgroundCover>
  )
}
