import React, { useRef, useEffect } from 'react'
import Slick from 'react-slick'
import arrowLeft from 'assets/svg/left-arrow-dark.svg'
import arrowRight from 'assets/svg/right-arrow-dark.svg'
import LazyImage from 'app/theme/LazyImage'

import './slick.css'
import './slick-theme.css'

function Dots(dots) {
  return <ul>{dots}</ul>
}

function Paging() {
  return <button />
}

export function PrevArrow(props) {
  const { className, onClick } = props

  return (
    <button className={className} onClick={onClick}>
      <LazyImage src={arrowLeft} />
    </button>
  )
}

export function NextArrow(props) {
  const { className, onClick } = props

  return (
    <button className={className} onClick={onClick}>
      <LazyImage src={arrowRight} />
    </button>
  )
}

export default function Slider(props) {
  const slider = useRef()

  const {
    current,
    dots = true,
    arrows = true,
    infinite = true,
    ...rest
  } = props

  useEffect(() => {
    slider.current && slider.current.slickGoTo(current)
  }, [current])

  return (
    <Slick
      className={dots && 'slick-has-dots'}
      dots={dots}
      arrows={arrows}
      infinite={infinite}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      lazyLoad={false}
      appendDots={Dots}
      customPaging={Paging}
      prevArrow={<PrevArrow />}
      nextArrow={<NextArrow />}
      ref={slick => (slider.current = slick)}
      {...rest}
    />
  )
}
