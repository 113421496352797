import React from 'react'
import { format } from 'date-fns'
import { MessageSquare } from 'react-feather'
import Stars from 'app/theme/Stars'

export default function CateringReviews(props) {
  const { 
    reviews, 
    total, 
    average_rating, 
    title = 'Recent Customer Reviews',
    subheading
  } = props

  const roundedAverageRating = Math.round(average_rating)

  if (!reviews?.length) {
    return null
  }

  return (
    <div className="container pt-16 py-6">
      <h2
        name="reviews"
        className="font-platform leading-none text-navy text-4xl text-center mb-12"
      >
        {title}
      </h2>
      { subheading && <p className='font-light text-xl w-full md:w-4/5 m-auto'>{subheading}</p> }
      <div className="flex w-full md:w-4/5 m-auto flex-col sm:flex-row items-center my-3">
        <div className="text-2xl md:text-xl flex-1 flex items-center">
          <MessageSquare fill={'#BFEAFB'} className="text-blue-200 mr-2" />{' '}
          <h3>
            <span className="font-bold pb-2">{total}</span> Reviews
          </h3>
        </div>
        <div className="flex items-center">
          <Stars rating={roundedAverageRating} size={16} className="mr-1" />
          <div className="font-bold text-xl pb-2">{roundedAverageRating}</div>
        </div>
      </div>

      {reviews.map(review => (
        <div className="w-full md:w-4/5 m-auto" key={review.id}>
          <Review review={review} />
        </div>
      ))}
    </div>
  )
}

function Review(props) {
  const { review } = props
  const dateDisplay = format(review.event.start_date, 'MM/dd/yyyy')
  return (
    <div className="border-t border-blue-400 py-5 flex flex-col sm:flex-row items-start">
      <div className="flex-1 text-lg">
        {review.event?.proposal?.contact?.first_name && (
          <p className="text-black capitalize">
            <span className="font-bold">{review.event.proposal.contact.first_name}</span> 
            {review.event?.address?.city_name && (
              <span className="font-light"> in {review.event.address.city_name}</span>
            )}
          </p>
        )}
       
        <p className="font-light">
          {review.event.occasion.name} | {review.event.eaters} People |{' '}
          {dateDisplay}
        </p>
        <p className="font-light text-xl text-black my-2">{review.comment}</p>
        <ReviewTags review_tags={review.review_tags} />
      </div>
      <div className="flex items-center">
        <Stars rating={review.rating} size={16} className="mr-1" />
        <span className="font-bold pb-1">{review.rating}</span>
      </div>
    </div>
  )
}

function ReviewTags(props) {
  const { review_tags } = props

  return (
    <div className="flex flex-wrap mb-2">
      {review_tags.map(review_tag => (
        <div
          key={review_tag.id}
          className={[
            'border border-black text-black font-light md:text-lg',
            'text-center py-1 px-2 mb-2 rounded-full mr-2 flex-row items-center',
          ]}
        >
          {review_tag.name}
        </div>
      ))}
    </div>
  )
}
