import { useRef, useEffect } from 'react'
import { useFeatureFlags } from 'app/utils/feature-flags'

export default function useEventTracker(key, initialValue) {
  const ref = useRef()
  const [featureFlags] = useFeatureFlags()

  const featureFlagEntries = Object.entries(featureFlags)

  useEffect(() => {
    ref.current = window.gtag
  }, [])

  return {
    event(action, params) {
      if (!ref.current) {
        return null
      } else {
        if (featureFlagEntries.length) {
          params = {
            ...params,
            feature_flags: featureFlagEntries.filter(f => f[1]).map(f => f[0]).join(',')
          }
        }
        return ref.current('event', action, params)
      }
    }
  }
}
