import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Layout } from 'app/theme/layout'
import ErrorPage from 'app/pages/ErrorPage'
import NotFoundPage from 'app/pages/NotFoundPage'
import { useQuery } from 'jsonapi-react'
import { useParams, Redirect } from 'react-router-dom'
import { Blocks } from 'app/cms/Blocks'
import CityCateringPageV1 from './CityCateringPage'

export default function CityCateringPageV2(props) {
  if (props.staticContext) {
    props.staticContext.cacheMaxAge = 604800
  }

  const params = useParams()

  const placeQuery = useQuery(['places', {
    basic: true,
    filter: {
      slug: params.citySlug,
    },
  }])

  const pageQuery = useQuery(['pages', {
    sort: '-priority',
    merge: true,
    expand_content: true,
    filter: {
      type: 'CityCatering',
      city: params.citySlug,
    },
  }])

  const place = placeQuery.data?.[0]
  const page = pageQuery.data?.[0]

  if (placeQuery.isLoading || pageQuery.isLoading) {
    return null
  }

  if (params.catererSlug) {
    return <Redirect to={`/${place.url_slug}/food-truck-catering/`} />
  }

  if (!placeQuery.data) {
    return <ErrorPage />
  }

  if (!place) {
    return <NotFoundPage />
  }

  if (['city', 'region'].includes(place.type) && place.url_slug != params.citySlug) {
    return <Redirect to={`/${place.url_slug}/food-truck-catering/`} />
  }

  if (page?.content?.layout !== 'custom') {
    return <CityCateringPageV1 {...props} placeQuery={placeQuery} pageQuery={pageQuery} />
  }

  return (
    <RenderPage
      page={page}
      place={place}
    />
  )
}

export function RenderPage({ page, place }) {
  const blockCtx = useMemo(() => ({
    page,
    place,
  }), [
    page,
    place,
  ])

  // Skip indexing if the page is a minor city with less than 3 caterers
  const skipIndexing = place.type !== 'neighborhood' && !place?.major && !place?.market && place?.caterers_count < 3


  return (
    <Layout>
      <Helmet>
        {page.content?.meta_title && (
          <title>{page.content.meta_title}</title>
        )}
        {page.content?.meta_description && (
          <meta name="description" content={page.content.meta_description}></meta>
        )}
        {page.content?.meta_keywords && (
          <meta name="description" content={page.content.meta_keywords}></meta>
        )}
        {page.content?.meta_title && (
          <meta name="og:title" content={page.content.meta_title}></meta>
        )}
        {page.json_schema && (
          <script type="application/ld+json">
            {page.json_schema}
          </script>
        )}
        {skipIndexing ? (
          <meta name="robots" content="noindex" />
        ) : null }
      </Helmet>
      <Blocks
        blocks={page?.content.blocks || []}
        context={blockCtx}
      />
    </Layout>
  )
}

