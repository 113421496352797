import React from 'react'
import JumpLink from './JumpLink'
import arrowNoCircle from 'assets/img/arrow-nocircle.png'
import styles from './JumpLinkArrow.module.css'

export default function JumpLinkArrow({ alt, className, ...props }) {
  return (
    <JumpLink
      {...props}
      className={[
        styles.arrow,
        className,
        'block border border-white rounded-full',
      ]}
    >
      <img alt={alt} src={arrowNoCircle} />
    </JumpLink>
  )
}
