import React from 'react'
import Slider from 'app/theme/slider'
import RankingBadge from 'app/theme/RankingBadge'
import ServiceTypeBadge from 'app/theme/ServiceTypeBadge'
import { getServiceIcon, getServiceLabels } from 'app/utils/catererServices'
import truckPic from 'assets/svg/truck.svg'
import forkKnife from 'assets/svg/fork-knife.svg'
import starBadge from 'assets/svg/star.svg'
import styles from './CatererCarousel.module.css'
import { getResizedPhotoUrl } from 'app/utils/photos'

export default function CatererCarousel(props) {
  const { caterers } = props

  return (
    <Slider
      className={styles.slider}
      slidesToShow={3}
      slidesToScroll={1}
      arrows={true}
      infinite={caterers.length >= 3 ? true : false}
      dots={false}
      variableWidth={false}
      centerMode={false}
      lazyLoad={true}
      responsive={[
        {
          breakpoint: 1200,
          settings: {
            dots: false,
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            variableWidth: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 991,
          settings: {
            dots: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            centerMode: true,
            infinite: true,
          },
        },
        {
          breakpoint: 549,
          settings: {
            dots: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            centerMode: caterers.length > 1 ? true : false,
            infinite: caterers.length > 1 ? true : false,
          },
        },
      ]}
    >
      {caterers.map(caterer => {
        return (
          <div key={caterer.id}>
            <div className={[styles.slide]}>
              <div
                style={{
                  height: 200,
                  backgroundImage: `url(${caterer.photo ? getResizedPhotoUrl(caterer.photo, 500, 335) : truckPic})`,
                  backgroundSize: caterer.photo ? undefined : '60%',
                }}
                className={[
                  'bg-gray-100 bg-cover bg-center bg-no-repeat',
                ]}
              />
              <div className="p-4 font-light">
                <h4 className="text-blue font-normal font-platform text-xl leading-tight mb-1">
                  <a href={`/${caterer.slug}/`}>
                    {caterer.name}
                  </a>
                </h4>
                <p className={styles.description}>
                  {caterer.short_description || caterer.description}
                </p>
                <div className={styles.badges}>
                  <div>
                    {caterer.ranking_tier ? (
                      <RankingBadge
                        ranking={caterer.ranking_tier}
                      />
                    ) : (
                      <img
                        src={forkKnife}
                      />
                    )}
                    {caterer.ranking_tier == 1 ? (
                      'Top Caterer'
                    ) : (
                      'Preferred Caterer'
                    )}
                    {caterer.tags.length && (
                      `, ${caterer.tags.slice(0,3).map(t => t.name).join(', ')}`
                    )}
                  </div>
                  {caterer.services.length > 0 && (
                    <div>
                      <img
                        src={getServiceIcon(caterer.services)}
                      />
                      {getServiceLabels(caterer.services).join(', ')}
                    </div>
                  )}
                  {caterer.good_fors.length > 0 && (
                    <div>
                      <img
                        src={starBadge}
                      />
                      {caterer.good_fors.slice(0,3).map(g => g.name).join(', ')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}
