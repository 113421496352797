import React, { useState, useRef } from 'react'
import { parse, format, set as setFn, isValid } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import { usePopper } from 'react-popper'
import FocusTrap from 'focus-trap-react'
import { Calendar } from 'react-feather'
import TimePicker from './TimePicker'
import 'react-day-picker/dist/style.css'
import moduleStyles from './DatePicker.module.css'

const theme = {
  div: null,
  label: 'label block mb-2',
  input: 'input-basic cursor-pointer',
  error: 'text-sm text-red-600 mt-1',
  icon: 'absolute right-0 mr-3 text-gray-600 pointer-events-none'
}

export default function DatePicker(props) {
  let {
    value,
    onChange = () => {},
    timePicker = false,
    className,
    classNames,
    disableBefore,
    disableAfter,
    divProps,
    label,
    labelProps,
    error,
    errorProps,
    icon,
    ...rest
  } = props

  const styles = { ...theme, ...classNames }

  const [selected, setSelected] = useState(value)
  const [inputValue, setInputValue] = useState(value ? format(value, 'MM-dd-y') : '')
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const [popperElement, setPopperElement] = useState(null)

  const popperRef = useRef(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  })

  const closePopper = () => {
    setIsPopperOpen(false)
  }

  const handleInputChange = (e) => {
    setInputValue(e.currentTarget.value)
    const date = parse(e.currentTarget.value, 'MM-dd-y', new Date())
    if (isValid(date)) {
      setSelected(date)
    } else {
      setSelected(undefined)
    }
  }

  const handleDaySelect = (day) => {
    setSelected(day)
    if (!day) {
      setInputValue('')
      onChange(null)
    } else if (value) { 
      setInputValue(format(day, 'MM-dd-y'))
      // merge the new "day" with the current "date" time
      onChange(setFn(day, {
        hours: value.getHours(),
        minutes: value.getMinutes()
      }))
    } else {
      setInputValue(format(day, 'MM-dd-y'))
      onChange(day)
    }
    closePopper()
  }

  

  if (disableBefore === 'today') {
    disableBefore = new Date()
  }

  let disabledDays

  if (disableBefore || disableAfter) {
    disabledDays = {
      before: disableBefore,
      after: disableAfter
    }
  }

  return (
    <div className={styles.div} {...divProps}>
      {label && (
        <label className={styles.label} {...labelProps}>
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        {icon !== false && (
          <Calendar
            size={20}
            className={styles.icon}
          />
        )}
        <div className="flex-1">
          <div ref={popperRef}>
            <input
              size={12}
              className={[styles.input, icon && 'pr-10']}
              type="text"
              readOnly
              placeholder={timePicker ? 'mm/dd/yyyy, hh:mm' : 'mm/dd/yyyy'}
              value={inputValue}
              onChange={handleInputChange}
              onClick={() => setIsPopperOpen(true)}
              {...rest}
            />
          </div>
          {isPopperOpen && (
            <FocusTrap
              active
              focusTrapOptions={{
                initialFocus: false,
                allowOutsideClick: true,
                clickOutsideDeactivates: true,
                onDeactivate: closePopper,
                fallbackFocus: popperRef.current || undefined
              }}
            >
              <div
                tabIndex={-1}
                style={{
                  ...popper.styles.popper,
                }}
                className="bg-white shadow-lg rounded z-10"
                {...popper.attributes.popper}
                ref={setPopperElement}
                role="dialog"
                aria-label="DayPicker calendar"
              >
                <DayPicker
                  initialFocus={isPopperOpen}
                  value={value}
                  mode="single"
                  defaultMonth={selected}
                  selected={selected}
                  onSelect={handleDaySelect}
                  disabled={disabledDays}
                  styles={{
                    day: { fontFamily: 'sans-serif' }
                  }}
                  modifiersClassNames={{
                    selected: moduleStyles.datepickerSelected,
                    today: moduleStyles.datepickerToday
                  }}
                />
              </div>
            </FocusTrap>
          )}
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}
