import truck from 'assets/svg/truck.svg'
import popup from 'assets/svg/popup-tent.svg'
import dropoff from 'assets/svg/drop-off.svg'
import trailer from 'assets/svg/trailer.svg'
import cart from 'assets/svg/cart.svg'
import traditional from 'assets/svg/full-service.svg'

const types = {
  truck: { icon: truck, label: 'Food Truck' },
  trailer: { icon: trailer, label: 'Trailer' },
  cart: { icon: cart, label: 'Cart' },
  popup: { icon: popup, label: 'Pop-Up' },
  dropoff: { icon: dropoff, label: 'Dropoff' },
  traditional: { icon: traditional, label: 'Full Service' },
}

export function getServiceIcon(services) {
  const type = Object.keys(types).find(type => (
    services.find(s => s.type === type)
  ))

  return type ? types[type].icon : null
}

export function getServiceLabel(service) {
  return service && types[service.type] ? types[service.type].label : 'Street Service'
}

export function getServiceLabels(services) {
  return services.map(s => s.type)
    .filter(unique)
    .filter(type => types[type])
    .map(type => types[type].label)
}

function unique(value, index, self) {
  return self.indexOf(value) === index
}
