import React from 'react'
import useTimeState from './useTimeState'

export default function TimePicker(props) {
  const {
    hour,
    minutes,
    meridiem,
    setHour,
    setMinutes,
    setMeridiem
  } = useTimeState(props.date, props.onChange)

  return (
    <div className={['flex items-center', props.className]}>
      <select
        value={hour}
        className="select-basic flex-1"
        onChange={event => {
          setHour(event.target.value)
        }}
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
      </select>
      <div className="px-1">:</div>
      <select
        value={minutes}
        className="select-basic flex-1 mr-2"
        onChange={event => {
          setMinutes(event.target.value)
        }}
      >
        <option value="0">00</option>
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="45">45</option>
      </select>
      <select
        value={meridiem}
        className="select-basic flex-1"
        onChange={event => {
          setMeridiem(event.target.value)
        }}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  )
}
