import React from 'react'
import Slider from 'app/theme/slider'
import { useQuery } from 'jsonapi-react'
import { getResizedPhotoUrl } from 'app/utils/photos'
import { useInView } from 'react-intersection-observer'

export default function FeaturedClients(props) {
  const {
    heading = 'Just a few of the people who have eaten lunch with us',
    className,
    buttonText = 'Get Started',
    buttonUrl,
    onButtonClick = null,
    city,
    ...rest
  } = props

  const [ref, inView] = useInView({
    rootMargin: '50px',
    triggerOnce: true,
  })

  const query = useQuery(['featured_clients', {
    include: 'photo',
    sort: 'position',
    filter: city ? { city_id: city.id } : { global: true },
    fields: {
      featured_clients: 'name',
      photos: ['path', 'uuid', 'extension'],
    },
    page: {
      size: 16  ,
    }
  }], {
    ssr: false
  })

  if (query.isLoading || !query.data?.length) {
    return null
  }

  const data = query.data.filter(c => c.photo)
  const size = 4

  const chunks = Array.from({
    length: Math.ceil(data.length / size)
  }, (v, i) => (
    data.slice(i * size, i * size + size)
  )).filter(chunk => (
    chunk.length > 3
  ))

  return (
    <div {...rest} ref={ref} className={['bg-white relative z-0', className]}>
      <div className="container py-16">
        <h3
          children={heading}
          className={[
            'font-platform text-navy text-4xl text-center leading-none'
          ]}
        />
        <Slider
          dots={false}
          className="my-12 mx-10"
          lazyLoad={true}
          responsive={[
            {
              breakpoint: 1000,
              settings: {
                arrows: false,
                dots: true,
              },
            }
          ]}
        >
          {chunks.map((clients, index) => (
            <div key={index}>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 mb-10 lg:mb-0">
                {clients.map(client => (
                  <div
                    key={client.id}
                    className="h-20 md:h-32 px-6 flex items-center justify-center"
                  >
                    <img
                      src={inView ? getResizedPhotoUrl(client.photo) : undefined}
                      title={client.name}
                      alt={client.name}
                      className="max-h-full"
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
        <div className="text-center">
          {buttonUrl ? (
            <a
              href={buttonUrl}
              children={buttonText}
              className="btn btn-green font-platform text-xl px-10 py-3"
            />
          ) : onButtonClick ? (
            <button
              onClick={onButtonClick}
              children={buttonText}
              className="btn btn-green font-platform text-xl px-10 py-3"
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
