import { getServiceLabel } from 'app/utils/catererServices'

export function getEventTitle(event) {
  if (event.title) {
    return event.title
  }

  if (event.event) {
    if (event.event.title) {
      return event.event.title
    }
    if (event.event.occasion) {
      return event.event.occasion.name
    }
  }

  if (event.caterer_service) {
    return getServiceLabel(event.caterer_service)
  }

  return null
}

export function getEventUrl(event, address, city) {
  address = address || event.address
  city = city || event.city
  return city ? `/events/${city.url_slug}/${event.id}/` : `/events/${event.id}/`
}
