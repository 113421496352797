import React, { useState, useEffect } from 'react'
import { PageContent } from './CustomPage'
import { RenderPage as RenderCityCateringPage } from './CityCateringPageV2'
import env from 'env'

export default function CustomPagePreview() {
  const [page, setPage] = useState()

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.origin !== env.KITCHEN_URL) {
        return
      }
      if (event.data?.page) {
        setPage(event.data.page)
      }
    })

    if (window.opener) {
      window.opener.postMessage('preview_init', env.KITCHEN_URL)
    }
  }, [])

  if (!page) {
    return null
  }

  return page.type === 'Custom' ? (
    <PageContent
      page={page}
    />
  ) : page.type === 'CityCatering' ? (
    <RenderCityCateringPage
      page={page}
      place={page.city}
    />
  ) : null
}

