import React from 'react'
import iceCream from 'assets/img/ice-cream.jpg'
import ImageSection from '../ImageSection'

export default function RepeatRepeat(props) {
  const {
    className,
    imageUrl = iceCream,
    heading = 'RepEAT, RepEAT',
    description = `Treat your guests to a delicious, unique meal again. Whether for
    a special celebration or every Friday, Roaming Hunger makes it
    simple to book another tasty event. Being the host with the most
    has never been easier.`,
    ...rest
  } = props

  return (
    <ImageSection
      imageUrl={imageUrl}
      className={['md:text-white', className]}
      heading={heading}
      description={description}
      {...rest}
    />
  )
}
