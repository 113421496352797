import React from 'react'

const theme = {
  div: null,
  label: 'label block mb-2',
  select: 'select-basic',
  error: 'text-sm text-red-600 mt-1',
}

export default function Select(props) {
  const {
    options = [],
    placeholder,
    value,
    className,
    classNames,
    onChange,
    divProps,
    label,
    labelProps,
    labelClassName,
    error,
    errorProps,
    ...selectProps
  } = props

  const styles = { ...theme, ...classNames }

  return (
    <div className={styles.div} {...divProps}>
      {label && (
        <label className={[styles.label, labelClassName]} {...labelProps}>
          {label}
        </label>
      )}
      <select
        {...selectProps}
        className={[styles.select, className]}
        value={onChange ? value || '' : value}
        onChange={event => {
          let val = event.target.value || null

          if (onChange) {
            onChange(val, event)
          }
        }}
      >
        {placeholder && (
          <option
            value=""
            children={placeholder}
          />
        )}
        {options.map(({ value, label, ...rest }) => (
          <option
            key={value}
            value={value}
            children={label}
            {...rest}
          />
        ))}
      </select>
      {error && (
        <div className={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}
