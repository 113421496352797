import React from 'react'
import JumpLinkArrow from './JumpLinkArrow'

import LazyImg from 'app/theme/LazyImage'

export default function ImageSection(props) {
  const {
    className,
    imageUrl,
    imageClassName,
    heading,
    children,
    description = children,
    sectionFontClass = "",
    mobileFontClass= "",
    jumpTarget,
    buttonText = 'Get Started',
    onButtonClick,
    ...rest
  } = props

  return (
    <section
      {...rest}
      className={[
        'relative bg-white md:h-screen md:flex md:items-center md:justify-end',
        sectionFontClass,
        mobileFontClass,
        className,
      ]}
    >
      <LazyImg
        src={imageUrl}
        rootMargin='200px 0px 200px 0px'
        className={[
          'md:absolute md:inset-0 md:h-full md:w-full md:object-cover',
          imageClassName,
        ]}
      />
      <div className="relative p-8 md:w-2/5 lg:w-1/3 xl:w-1/4 md:mr-20 xl:mr-40">
        <h2 className="text-2xl leading-tight mb-2 hidden md:block">
          {heading}
        </h2>
        <h2 className="text-2xl text-blue leading-tight mb-2 block md:hidden">
          {heading}
        </h2>
        <p className="font-light text-lg mb-6 hidden md:block">
          {description}
        </p>
        <p className="font-light text-black text-lg mb-6 block md:hidden">
          {description}
        </p>
        <button
          onClick={onButtonClick}
          children={buttonText}
          className="btn btn-green font-platform text-xl px-10 py-3"
        />
      </div>
      {jumpTarget && (
        <div className="absolute bottom-0 hidden md:flex justify-center w-full mb-10">
          <JumpLinkArrow
            target={jumpTarget}
          />
        </div>
      )}
    </section>
  )
}
