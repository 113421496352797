import React from 'react'
import Slider from 'app/theme/slider'
import styles from './ImageCarousel.module.css'
import { useInView } from 'react-intersection-observer'

export default function ImageCarousel(props) {
  const {
    heading,
    items = [],
    buttonText = 'Get Started',
    className,
  } = props

  const [ref, inView] = useInView({
    rootMargin: '50px',
    triggerOnce: true,
  })

  return (
    <div ref={ref} className={[className]}>
      <div className="container">
        <h2 className="font-platform text-5xl text-center leading-tight mb-3 text-navy">
          {heading}
        </h2>
        <Slider
          lazyLoad={true}
          className="flex mx-auto"
          slidesToShow={2}
          slidesToScroll={2}
          arrows={false}
          dots={true}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                dots: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {items.map((item, index) => (
            <div key={index} className="p-4">
              <div
                className={[styles.image, 'w-full mb-2']}
                style={{ backgroundImage: inView ? `url('${item.image_url}')` : undefined }}
              />
              <h3 className="font-platform text-2xl leading-tight my-5 text-navy">
                {item.heading}
              </h3>
              <p className="font-light leading-snug mb-5">
                {item.description}
              </p>
              {item.link_url && (
                <a
                  href={item.link_url}
                  className="btn btn-green font-platform text-1xl px-10 py-2"
                  children={item.link_text || 'View More'}
                />
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
