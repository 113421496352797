import React from 'react'
import { LocationAutoComplete } from 'app/maps'

const theme = {
  div: null,
  label: 'label block mb-2',
  input: 'input-basic',
  error: 'text-sm text-red-600 mt-1',
}

export default function Location(props) {
  const {
    types,
    value,
    onChange,
    className,
    classNames,
    divProps,
    label,
    labelProps,
    labelClassName,
    error,
    errorProps,
    onBlur = () => {},
    ...inputProps
  } = props

  const styles = { ...theme, ...classNames }

  return (
    <div className={styles.div} {...divProps}>
      {label && (
        <label className={[styles.label, labelClassName]} {...labelProps}>
          {label}
        </label>
      )}
        <LocationAutoComplete
          types={types}
          onPlaceChanged={location => {
            onChange && onChange(location.formatted, location)
          }}
        >
          <input
            type="text"
            {...inputProps}
            className={[styles.input, className]}
            value={onChange ? value || '' : value}
            onChange={event => {
              onChange && onChange(event.target.value || null, null)
            }}
            onBlur={onBlur}
          />
        </LocationAutoComplete>
      {error && (
        <div className={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}
