import React from 'react'
import tacos from 'assets/img/tacos.jpg'
import ImageSection from '../ImageSection'

export default function MakeEveryoneHappy(props) {
  const {
    className,
    imageUrl = tacos,
    heading = 'Make Everyone Happy',
    description = `Choose from thousands of cuisine options and let guests customize
    individual orders during your event. No matter the dietary requirements,
    every tastebud will be satisfied.`,
    ...rest
  } = props

  return (
    <ImageSection
      imageUrl={imageUrl}
      className={[className ?? 'md:text-white']}
      heading={heading}
      description={description}
      {...rest}
    />
  )
}
