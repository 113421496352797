import React from 'react'
import Tooltip from './Tooltip'

import truck from 'assets/svg/truck.svg'
import popup from 'assets/svg/popup-tent.svg'
import dropoff from 'assets/svg/drop-off.svg'
import trailer from 'assets/svg/trailer.svg'
import cart from 'assets/svg/cart.svg'
import traditional from 'assets/svg/full-service.svg'

const icons = {
  truck,
  popup,
  cart,
  trailer,
  dropoff,
  traditional,
}

export default function ServiceTypeBadge({ services = [], ...rest }) {
  const icon = Object.keys(icons).find(type => (
    services.find(s => s.type === type)
  ))

  if (!icon) {
    return null
  }

  return (
    <img src={icons[icon]} {...rest} />
  )
}
