import { differenceInCalendarYears, differenceInMonths } from 'date-fns'

export function timeInBusiness(date) {
  const now = new Date()
  const start = new Date(date)
  const years = differenceInCalendarYears(now, start)

  if (years > 1) {
    return `${years}+ Year${years > 1 ? 's' : ''}`
  }

  const months = differenceInMonths(now, start)
  if (months > 0) {
    return `${months} Month${months > 1 ? 's' : ''}`
  }

  return `New`;
}
