import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Layout, Header } from 'app/theme/layout'
import NotFoundPage from 'app/pages/NotFoundPage'
import LeadModal from 'app/leads/LeadModal'
import { useModalState } from 'lib/modal'
import { useQuery } from 'jsonapi-react'
import { useLocation } from 'react-router-dom'
import HowItWorks from 'app/theme/sections/HowItWorks'
import HowItWorksLarge from 'app/theme/sections/HowItWorksLarge'
import ImageHero from 'app/theme/ImageHero'
import FAQ from './blocks/FrequentlyAskedQuestions'
import CuisineTypes from 'app/theme/sections/CuisineTypes'
import WhyWorkWithUs from 'app/theme/sections/WhyWorkWithUs'
import FeaturedClients from 'app/theme/sections/FeaturedClients'
import Calculator from 'app/theme/calculator'
import ContentGrid from './blocks/ContentGrid'
import ImageCarousel from './blocks/ImageCarousel'
import useAgencyLeadModal from 'app/agency/useLeadModal'
import { useFeatureFlagVariantKey } from 'lib/posthog'

export default function CustomPage({ staticContext }) {
  if (staticContext) {
    staticContext.cacheMaxAge = 604800
  }

  const location = useLocation()

  const pageQuery = useQuery(['pages', {
    filter: {
      type: 'Custom',
      url: location.pathname,
    },
  }])

  if (pageQuery.isLoading) {
    return null
  }

  if (!pageQuery.data?.length) {
    return <NotFoundPage />
  }

  const page = pageQuery.data[0]

  return (
    <PageContent
      page={page}
    />
  )
}

export function PageContent({ page }) {
  const { openModal, ...modalState } = useModalState()
  const { Modal: AgencyLeadModal, openModal: openAgencyLeadModal } = useAgencyLeadModal()

  function getButtonClickFn(block) {
    switch (block.data?.button_type) {
      case 'catering_lead':
        return openModal
      case 'agency_lead':
        return openAgencyLeadModal
      default:
        undefined
    }
  }

  return (
    <Layout header={<Header occasionMenu />}>
      <Helmet>
        <title>{page.content?.meta_title}</title>
        <meta name="description" content={page.content?.meta_description}></meta>
        {page.content?.meta_keywords && (
          <meta name="description" content={page.content.meta_keywords}></meta>
        )}
        {page.content?.meta_title && (
          <meta name="og:title" content={page.content.meta_title}></meta>
        )}
        {page.json_schema && (
          <script type="application/ld+json">
            {page.json_schema}
          </script>
        )}
      </Helmet>
      <LeadModal
        {...modalState}
      />
      <AgencyLeadModal />
      <RenderBlocks
        blocks={page.content?.blocks || []}
        openModal={openModal}
        getButtonClickFn={getButtonClickFn}
      />
    </Layout>
  )
}

function Experiment({
  block,
  openModal,
  getButtonClickFn,
}) {
  const variant = useFeatureFlagVariantKey(block.data.feature_flag || '')

  return variant && variant === block.data.variant ? (
    <RenderBlocks
      blocks={block.data.blocks || []}
      openModal={openModal}
      getButtonClickFn={getButtonClickFn}
    />
  ) : null
}

function RenderBlocks({
  blocks = [],
  openModal,
  getButtonClickFn,
}) {
  return blocks.map((block, index) => (
    <div key={index}>
      <RenderBlock
        block={block}
        openModal={openModal}
        getButtonClickFn={getButtonClickFn}
      />
    </div>
  ))
}

function RenderBlock({
  block,
  openModal,
  getButtonClickFn,
}) {
  return (
    block.type === 'Experiment' ? (
      <Experiment
        block={block}
        openModal={openModal}
        getButtonClickFn={getButtonClickFn}
      />
    ) : block.type === 'HowItWorks' ? (
      <>
        <HowItWorks
          heading={block.data?.heading}
          buttonUrl={block.data?.button_url}
          buttonText={block.data?.button_text}
          onButtonClick={getButtonClickFn(block)}
          items={block.data?.items?.map(item => ({
            title: item.heading,
            description: item.description,
          }))}
        />
        <HowItWorksLarge
          heading={block.data?.heading}
          buttonUrl={block.data?.button_url}
          buttonText={block.data?.button_text}
          onButtonClick={getButtonClickFn(block)}
          image={block.data?.image_url}
          items={block.data?.items?.map(item => ({
            title: item.heading,
            description: item.description,
          }))}
        />
      </>
    ) : block.type === 'ImageHero' ? (
      <ImageHero
        imageUrl={block.data?.image_url}
        title={block.data?.heading}
        description={block.data?.description}
        buttonUrl={block.data?.button_url}
        buttonText={block.data?.button_text}
        onButtonClick={getButtonClickFn(block)}
      />
    ) : block.type === 'Richtext' ? (
      <div className="container text-center py-12">
        <h2 className="text-navy text-4xl leading-tight font-platform">
          {block.data?.heading}
        </h2>
        <p
          className="font-light text-gray-900 mt-6"
          dangerouslySetInnerHTML={{ __html: block.data?.content || '' }}
        />
      </div>
    ) : block.type === 'FrequentlyAskedQuestions' ? (
      <FAQ
        heading={block.data?.heading}
      />
    ) : block.type === 'FoodTruckCalculator' ? (
      <Calculator
        padding="pt-12 pb-10"
        onButtonClick={openModal}
        heading={block.data?.heading}
      />
    ) : block.type === 'FeaturedClients' ? (
      <FeaturedClients
        heading={block.data?.heading}
        buttonUrl={block.data?.button_url}
        buttonText={block.data?.button_text}
        onButtonClick={getButtonClickFn(block)}
      />
    ) : block.type === 'CuisineGrid' ? (
      <CuisineTypes
        baseUrl='/food-truck-catering'
        heading={block.data?.heading}
        description={block.data?.description}
        staticHtml={false}
      />
    ) : block.type === 'ContentGrid' ? (
      <ContentGrid className="py-10">
        {block.data?.items?.map((item, index) => (
          item.type === 'Image' ? (
            <ContentGrid.Image
              key={index}
              imageUrl={item.image_url}
            />
          ) : item.type === 'Text' ? (
            <ContentGrid.Text
              key={index}
              heading={item.heading}
              content={item.content}
            />
          ) : item.type === 'TextImage' ? (
            <ContentGrid.TextImage
              key={index}
              heading={item.heading}
              content={item.content}
              imageUrl={item.image_url}
              imagePosition={index % 2 === 0 ? 'right' : 'left'}
            />
          ) : null
        ))}
      </ContentGrid>
    ) : block.type === 'WhyWorkWithUs' ? (
      <WhyWorkWithUs
        heading={block.data?.heading}
        subheading={block.data?.subheading}
        description={block.data?.description}
        buttonUrl={block.data?.button_url}
        buttonText={block.data?.button_text}
        onButtonClick={getButtonClickFn(block)}
      />
    ) : block.type === 'ImageCarousel' ? (
      <ImageCarousel
        {...block.data}
        className="pt-12 pb-10"
      />
    ) : null
  )
}
