import React from 'react'
import ImageHero from 'app/theme/ImageHero'
import nachos from 'assets/img/nachos.jpg'

export default function FoodTruckCatering(props) {
  const {
    className,
    title = 'Food Truck Catering',
    description = 'Hire the right gourmet food truck for your next catered event',
    buttonText = 'Get Started',
    imageUrl = nachos,
    ...rest
  } = props

  return (
    <ImageHero
      {...rest}
      title={title}
      description={description}
      imageUrl={imageUrl}
      showOverlay={true}
      className={[
        className,
      ]}
      nextSectionAltText="View next section"
    />
  )
}
