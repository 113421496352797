import React from 'react'
import LazyImage from 'app/theme/LazyImage'
import styles from './ContentGrid.module.css'

export default function ContentGrid(props) {
  return (
    <div className={[props.className, 'container']}>
      <div className="grid md:grid-cols-2 gap-8 md:gap-4">
        {props.children}
      </div>
    </div>
  )
}

ContentGrid.Image = function(props) {
  return (
    <div className={[props.className, 'flex md:py-4 items-center justify-center']}>
      <LazyImage src={props.imageUrl}
        className={['block max-w-full']}
      />
    </div>
  )
}

ContentGrid.Text = function(props) {
  return (
    <div className={[props.className, 'flex md:py-4 items-center justify-center']}>
      <div className={styles.textbox}>
        <h3 className="text-navy text-2xl">
          {props.index ? (`${props.index}.`) : ""} {props.heading}
        </h3>
        <div className="font-light text-black text-md">
          {props.children || props.content}
        </div>
      </div>
    </div>
  )
}

ContentGrid.TextImage = function(props) {
  return (
    <div className={['grid md:grid-cols-2 gap-4 md:gap-4 col-span-2']}>
      <ContentGrid.Image
        imageUrl={props.imageUrl}
        className={props.imagePosition === 'right' ? 'md:order-last' : null}
      />
      <ContentGrid.Text
        heading={props.heading}
        content={props.content}
      />
    </div>
  )
}