import React from 'react'
import guarantee from 'assets/svg/guarantee.svg'
import headphones from 'assets/svg/just-in-case-support.svg'
import lock from 'assets/svg/lock.svg'
import ace from 'assets/svg/ace-card.svg'
import certified from 'assets/svg/certified.svg'
import styles from './WhyWorkWithUs.module.css'
import LazyImage from 'app/theme/LazyImage'

const defaultSubHeading = 'Your food truck catering event is protected by the 100% Roaming Hunger Service Guarantee.'

export default function WhyWorkWithUs(props) {
  const {
    heading = 'Why Work With Us?',
    subheading = defaultSubHeading,
    buttonText = 'Get Started',
    padding = 'py-16',
    buttonUrl,
    placeName,
    onButtonClick,
    ...rest
  } = props

  return (
    <section {...rest}>
      <div className={['container text-center', padding]}>
        <h2 className="font-platform text-navy text-4xl">
          {heading}
        </h2>
        <div>
          <LazyImage
            src={guarantee}
            className="mx-auto my-8"
            style={{ maxWidth: 100 }}
          />
          <h3 className="text-navy text-3xl leading-none mb-4">
            {subheading}
          </h3>
          <p className="text-xl leading-tight mb-10 mx-auto" style={{ maxWidth: 650 }}>
            <span>
              We guarantee every {placeName} food truck catering event, every time. 
              Because food just tastes better when you have peace of mind. 
              <a className="text-blue" href="/food-truck-catering/roaming-hunger-guarantee/"> Read our service guarantee</a> for more details.
            </span>
          </p>
        </div>
        <div className="md:flex md:flex-wrap md:items-stretch md:-mx-4">
          <Card>
            <i><LazyImage className="mx-auto" src={lock} /></i>
            <h3>Protected <br />Payments</h3>
            <p>
              We hold the caterer's payment in escrow to guarantee you get{' '}
              fantastic day-of service. Our vendors get paid in full only{' '}
              after each event, ensuring your payment is protected.
            </p>
          </Card>
          <Card>
            <i><LazyImage className="mx-auto" src={headphones} /></i>
            <h3>Just-in-Case <br />Support</h3>
            <p>
              Every event booking comes with our Just-in-Case Support program{' '}
              to make sure that if anything unforeseen happens, we can have a{' '}
              great replacement ready to roll.
            </p>
          </Card>
          <Card>
            <i><LazyImage className="mx-auto" src={certified} /></i>
            <h3>Certified <br />Caterers</h3>
            <p>
              With a network of over 21,000 food trucks, carts, pop-ups, and caterers, we offer the best, 
              proven selection of reliable and delicious catering options{placeName && ` in ${placeName}`}.
            </p>
          </Card>
          <Card>
            <i><LazyImage className="mx-auto" src={ace} /></i>
            <h3>Tap into<br /> Our Experience</h3>
            <p>
              Facilitating over ten million meals since our inception, we 
              know what it takes to make your event a yummy success. Book 
              with Roaming Hunger knowing that you are working with the most 
              experienced team in the industry.
            </p>
          </Card>
        </div>
        {onButtonClick ? (
          <button
            type="button"
            children={buttonText}
            className="btn btn-green font-platform px-10 py-3 text-2xl mt-5"
            onClick={onButtonClick}
          />
        ) : buttonUrl ? (
          <a
            href={buttonUrl}
            children={buttonText}
            className="btn btn-green font-platform px-10 py-3 text-2xl mt-5"
          />
        ): null}
      </div>
    </section>
  )
}

function Card({ children }) {
  return (
    <div className={[styles.card, 'mb-6 md:w-1/2 md:px-4 lg:w-1/4']}>
      <div className="bg-white shadow p-10 h-full">
        {children}
      </div>
    </div>
  )
}
