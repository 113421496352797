import React from 'react';
import { Layout } from 'app/theme/layout'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

export default function NotFoundPage() {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = 404
        }
        return (
          <Layout>
            <Helmet>
              <title>404 - Roaming Hunger</title>
              <meta name="robots" content="noindex" />
            </Helmet>
            <div className="container py-10">
              <h1 className="font-bold font-platform text-blue leading-tight" style={{ 'fontSize': 90}}>Oh No!</h1>
              <p className="text-xl mb-5">Sorry this page doesn't exist. You can try some of these links instead:</p>
              <ul className="text-blue">
                <li><a className="hover:underline" href="/">Home</a></li>
                <li><a className="hover:underline" href="/food-truck-catering/">Catering</a></li>
                <li><a className="hover:underline" href="/food-trucks/">Find Trucks</a></li>
                <li><a className="hover:underline" href="/experiential-marketing-agency/">Brand Activations</a></li>
              </ul>
            </div>
          </Layout>
        )
      }}
    />
  )
}
