import React from 'react'
import Tooltip from './Tooltip'

import goldBadge from 'assets/svg/gold-badge.svg'
import roseBadge from 'assets/svg/rose-gold-badge.svg'

const preferredCaterer = (
  <div className="p-2 leading-snug">
    <div>PREFERRED CATERER</div>
    <div className="font-light">Customers love to hire this caterer</div>
  </div>
)

const topCaterer = (
  <div className="p-2 leading-snug">
    <div>TOP CATERER</div>
    <div className="font-light">This is a top ranked caterer on Roaming Hunger</div>
  </div>
)

export default function RankingBadge({ ranking, className }) {
  return (
    <Tooltip
      content={ranking == 1 ? topCaterer : preferredCaterer}
    >
      <img
        src={ranking == 1 ? goldBadge : roseBadge}
        className={className}
      />
    </Tooltip>
  )
}
