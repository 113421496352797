import React, { useState } from 'react'
import { stringify } from 'qs'
import env from 'env'

const BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap'

export default function StaticGoogleMap(props) {
  const {
    lat,
    lng,
    center,
    markerColor = 'EA4236',
    zoom = 6,
    width = 760,
    height = 500,
    style,
    ...rest
  } = props

  const url = `${BASE_URL}?${stringify({
    key: env.GOOGLE_API_KEY,
    zoom: zoom,
    size: `${width}x${height}`,
    center: center || `${lat},${lng}`,
    scale: 2,
    markers: lat && lng ? `size:mid|color:0x${markerColor}|${lat},${lng}` : undefined,
  })}`

  return (
    <div
      {...rest}
      style={{
        background: `url(${url}) no-repeat center center`,
        backgroundSize: 'cover',
        ...style,
      }}
    />
  )
}
