import React, { useCallback } from 'react'
import jump from 'jump.js'

export default function JumpLink(config) {
  const {
    target,
    duration = 1000,
    offset = 0,
    easing,
    onComplete: callback,
    onClick,
    ...props
  } = config
  
  const handleClick = useCallback(event => {
    if (onClick) {
      onClick(event)
      if (event.defaultPrevented) {
        return
      }
    }

    event.preventDefault()

    jump(target, {
      offset,
      easing,
      duration,
      callback,
    })
  }, [
    onClick,
    offset,
    easing,
    duration,
    callback,
  ])

  return (
    <a
      {...props}
      href={target}
      onClick={handleClick}
    />
  )
}
