import React from 'react'
import guarantee from 'assets/svg/guarantee.svg'
import truckParty from 'assets/svg/food-truck-party.svg'
import LazyImage from 'app/theme/LazyImage'

export default function(props) {
  const { onButtonClick } = props

  return(
    <div className="bg-navy py-10">
      <div className="-mt-16">
        <div className="container flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-2/5 text-center lg:text-left px-4 pt-12 lg:pt-20 pb-12">
            <p className="font-platform text-4xl md:text-5xl lg:text-6xl text-white leading-none my-5">
              Book catering for your next event
            </p>
            <p className="lg:flex text-white font-national leading-none text-xl md:text-2xl">
              <LazyImage alt="Service Guarantee" className="mr-4 hidden lg:inline-block" width="40" src={guarantee} />
              Protected by our 100% Roaming Hunger Service Guarantee
            </p>
            <button
              onClick={onButtonClick}
              className="bg-green text-white text-4xl lg:text-2xl mt-8 lg:mt-4 font-platform py-2 px-10"
            >
              Get Started
            </button>
          </div>
          <div className="w-full lg:w-3/5 px-4">
            <LazyImage src={truckParty} alt="Food Truck" />
          </div>
        </div>
      </div>
    </div>
  )
}
