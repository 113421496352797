import React from 'react'

export default function Radio(props) {
  const {
    label = null,
    size,
    checked,
    defaultChecked,
    onChange,
    className,
    ...rest
  } = props

  return (
    <label className={['radio', className]}>
      <input
        {...rest}
        type="radio"
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={event => {
          onChange && onChange(event.target.checked, event)
        }}
      />
      <div />
      <span>{label}</span>
    </label>
  )
}
