import React, { useState, useEffect, useMemo } from 'react'
import { Input, Location, Checkbox } from 'app/theme/forms'
import { LocationAutoComplete } from 'app/maps'
import { useClient } from 'jsonapi-react'
import calculator from 'assets/svg/calculator.svg'
import { calculate, numberWithCommas, minimumDefaults } from './helpers'
import { useInView } from 'react-intersection-observer'

const foodTypes = [
  { label: 'Entree', value: 'main' },
  { label: 'Drink', value: 'drink' },
  { label: 'Dessert', value: 'dessert' },
]

export default function Calculator(props) {
  const {
    className,
    padding = 'py-12 md:py-32',
    heading = 'How Much Does Food Truck Catering Cost?',
    subheading,
    buttonText = 'Get Started',
    onButtonClick,
    cateringTagPage = false,
    cateringPage = false,
    cuisinePage = false,
    cateringMinimum,
    pricePerPerson,
    occasion,
    ...rest
  } = props

  const client = useClient()

  const [estimate, setEstimate] = useState(0)
  const [minimum, setMinimum] = useState(cateringMinimum)

  const [data, setData] = useState({
    address: { city_name: "" },
    eaters: null,
    foods: [],
  })

  const onFoodChange = (food, checked) => {
    if (checked) {
      setData(prev => ({
        ...prev,
        foods: prev.foods.concat(food),
      }))
    } else {
      setData(prev => ({
        ...prev,
        foods: prev.foods.filter(f => f !== food),
      }))
    }
  }

  useEffect(() => {
    if(cuisinePage) {
      return
    }

    if (!data.address || !data.address.location) {
      return
    }

    client.fetch(['catering_minimums', {
      sort: 'distance',
      filter: {
        location: [
          data.address.location.lng,
          data.address.location.lat
        ],
      },
      extra_fields: {
        catering_minimums: ['distance']
      },
      page: {
        size: 1,
      },
    }]).then(res => {
      if (res.data?.length) {
        setMinimum(res.data[0])
      }
    })
  }, [data.address])

  useEffect(() => {
    if (!minimum) {
      return
    }
    if (data.foods.length === 0) {
      setEstimate(0);
    } else {
    setEstimate(calculate({
      foods: data.foods,
      eaters: data.eaters,
      catering: cateringMinimum || minimum.catering_min || minimumDefaults.catering,
      dessert: minimum.dessert_min || minimumDefaults.dessert,
      cateringTagPage,
      cateringPage,
      cuisinePage,
      pricePerPerson,
      occasion,
    }))
  }}, [minimum, data.foods, data.eaters])

  const [ref, inView] = useInView({
    rootMargin: '50px',
    triggerOnce: true,
  })

  return (
    <div {...rest} ref={ref} className={[className]}>
      <div className={['container md:flex', padding]}>
        <div
          children={<img src={inView ? calculator : undefined} />}
          className={[
            'hidden md:block w-1/6 mr-20',
          ]}
        />
        <div className="md:flex-1">
          <div className="mb-6 text-center md:text-left">
            <h3 className="font-platform leading-none text-navy text-4xl md:text-5xl">
              {heading}
            </h3>
            {subheading && (
              <p className='font-light text-xl'>{subheading}</p>
            )}
          </div>
          {inView && (
            <div className="grid gap-5 md:grid-cols-2">
              <div>
                  <label className='label block mb-2'>
                    City
                  </label>
                  <LocationAutoComplete
                    types={['(cities)']}
                    onPlaceChanged={address => {
                      setData( prev => ({ ...prev, address }) )
                    }}
                  >
                    <input
                      type="text"
                      className="input-basic"
                      value={data.address?.city_name}
                      onChange={event => {
                        event.persist()
                        setData(prev => ({
                          ...prev,
                          address: {
                            ...prev.address,
                            city_name: event.target.value,
                          }
                        }))
                      }}
                    />
                  </LocationAutoComplete>

              </div>

              <Input
                type="number"
                label="How many eaters"
                value={data.eaters}
                onChange={eaters => {
                  setData(prev => ({ ...prev, eaters }))
                }}
              />
            </div>
          )}
          <div className="mt-6">
            <label className="label block mb-5">
              What are you serving <span className="text-sm ml-2">(Check all that apply)</span>
            </label>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-5 xl:w-1/2">
              {foodTypes.map(food => (
                <Checkbox
                  key={food.value}
                  className="checkbox-green"
                  label={food.label}
                  value={food.value}
                  checked={data.foods.indexOf(food.value) >= 0}
                  onChange={checked => {
                    onFoodChange(food.value, checked)
                  }}
                />
              ))}
            </div>
          </div>
          <div className="mt-10">
            <div className="leading-none text-3xl font-bold text-gray-800 mb-1">
              Starting Prices:
            </div>
            <div className="leading-none font-bold text-green text-5xl">
              ${numberWithCommas(estimate)} USD
            </div>
          </div>
          <div className="mt-6">
            <button
              onClick={onButtonClick}
              children={buttonText}
              className="btn btn-green font-platform text-2xl px-10 py-2"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
