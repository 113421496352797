import React from 'react'
import Tooltip from 'app/theme/Tooltip'
import icon from 'assets/svg/certified.svg'

export default function CertifiedCheckmark(props) {
  return (
    <Tooltip content={<CertifiedText />}>
      <img src={icon} {...props} />
    </Tooltip>
  )
}

function CertifiedText() {
  return (
    <div className="p-2 leading-snug max-w-20">
      <div className="uppercase">
        Certified Caterer
      </div>
      <div className="font-light text-md">
        This caterer is insured and ready to cater your event
      </div>
    </div>
  )
}
