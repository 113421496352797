import React from 'react'

export default function BackgroundCover(props) {
  const {
    src,
    imageUrl = src,
    className,
    opacityClass = 'bg-opacity-25',
    role,
    style,
    showOverlay,
    ...rest
  } = props

  const ariaLabel = rest['aria-label']
  delete rest['aria-label']

  if (showOverlay) {
    return (
      <div
        style={{
          backgroundImage: `url(${imageUrl})`,
          ...style,
        }}
        className={[
          className,
          'bg-cover bg-center bg-no-repeat',
        ]}
        role={role}
        aria-label={ariaLabel}
      >
        <div 
          {...rest}
          className={[
            'flex flex-col justify-center items-center',
            `absolute w-full h-full ${opacityClass} bg-black`
          ]}
        />
      </div>
    )    
  }

  return (
    <div
      {...rest}
      style={{
        backgroundImage: `url(${imageUrl})`,
        ...style,
      }}
      className={[
        className,
        'bg-cover bg-center bg-no-repeat',
      ]}
    />
  )
}
