import React from 'react'
import styles from './HowItWorks.module.css'

const defaultItems = [
  { title: 'Give Us Details', description: 'Tell us when & where you want food truck catering.' },
  { title: 'Choose Your Cuisine', description: 'We\'ll send you a list of available food trucks.' },
  { title: 'Get Ready to Dig In', description: 'We\'ll negotiate the best rate and coordinate the logistics.' },
]

export default function HowItWorks(props) {
  const {
    heading = 'How It Works',
    className,
    buttonText = 'Get Started',
    buttonUrl,
    onButtonClick,
    items = defaultItems,
    hiddenLg = true,
    ...rest
  } = props

  return (
    <section
      {...rest}
      className={[
        className,
        'bg-navy text-white text-center pt-16 pb-20',
        hiddenLg && 'lg:hidden'
      ]}
    >
      <div className="container">
        <h2 className="font-platform mb-10 text-3xl lg:text-4xl">
          {heading}
        </h2>
        <div className="lg:flex">
          {items.map((item, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.circle}>
                {index + 1}
              </div>
              <div className="">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        {onButtonClick ? (
          <button
            type="button"
            children={buttonText}
            className={['btn', styles.button]}
            onClick={onButtonClick}
          />
        ) : buttonUrl ? (
          <a
            href={buttonUrl}
            children={buttonText}
            className={['btn', styles.button]}
          />
        ) : null}
      </div>
    </section>
  )
}
