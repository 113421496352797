import React from 'react'
import styles from './FrequentlyAskedQuestions.module.css'

export default function FrequentlyAskedQuestions(props) {
  const {
    heading,
  } = props

  return (
    <div className="bg-navy">
      <div className="container p-6">
        <div className="flex w-full">
          <div className="w-full py-16">
            <h2 className="text-white text-4xl leading-none font-platform text-center">
              {heading || 'Frequently Asked Questions'}
            </h2>
            <div className="w-full md:w-4/5 mb-2 mt-6 m-auto">
              <h3 className="text-white text-3xl">How much does food truck catering cost?</h3>
              <div className={[styles.faq, 'text-white text-lg']}>
                <p>
                  On average, food truck catering costs $1,541, and pricing for a catering package can
                  typically range from $800-$2,500.
                </p>
                <p>
                  Cuisine affects the costs of your food truck catering more than any other factor. Here
                  are typical per-person prices along with average minimums.
                </p>
                <table className="w-full table-auto border-spacing-px border-collapse border border-navy-300 my-4">
                  <thead>
                    <tr>
                      <th className="text-left p-2 border border-navy-300">Cuisine</th>
                      <th className="text-left p-2 border border-navy-300">Average Price per Person</th>
                      <th className="text-left p-2 border border-navy-300">1-100 Eaters (Minimum)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-2 border border-navy-300 font-light">
                        <a href="/food-truck-catering/taco-truck-catering/" target="blank" className="text-blue-400">Taco Truck Catering</a>
                      </td>
                      <td className="p-2 border border-navy-300 font-light">$10-15</td>
                      <td className="p-2 border border-navy-300 font-light">$1,000</td>
                    </tr>
                    <tr>
                      <td className="p-2 border border-navy-300 font-light">
                        <a href="/food-truck-catering/burger-catering-truck/" target="blank" className="text-blue-400">Burger</a>, &nbsp;
                        <a href="/food-truck-catering/bbq-food-truck-catering/" target="blank" className="text-blue-400">BBQ</a>, & &nbsp;
                        <a href="/food-truck-catering/pizza-truck-catering/" target="blank" className="text-blue-400">Pizza Truck Catering</a>
                      </td>
                      <td className="p-2 border border-navy-300 font-light">$15-25</td>
                      <td className="p-2 border border-navy-300 font-light">$1,500</td>
                    </tr>
                    <tr>
                      <td className="p-2 border border-navy-300 font-light">Dessert Truck Catering</td>
                      <td className="p-2 border border-navy-300 font-light">$8-20</td>
                      <td className="p-2 border border-navy-300 font-light">$800</td>
                    </tr>
                    <tr>
                      <td className="p-2 border border-navy-300 font-light">Beverage Truck Catering</td>
                      <td className="p-2 border border-navy-300 font-light">$8-20</td>
                      <td className="p-2 border border-navy-300 font-light">$800</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Use our <a href="#calculator" className="text-blue-400 underline">Food Truck Catering Cost Calculator</a>
                </p>
                <p>
                  Adding drinks or dessert options (with a savory food truck) will increase the price per
                  person by $1-$5.
                </p>
                <p>
                  If you're having a small party of 50 guests or less, you'll probably be affected by the
                  food truck catering minimums, and the price per person will increase.
                </p>
                <p>
                  Typical service times are 2-3 hours. Additional hours will increase total costs. Travel
                  fees can also affect pricing. Food trucks may charge a per-mile fee if your event is
                  outside their normal travel radius (20-30 miles).
                </p>
                <p>
                  This data is based on historical Roaming Hunger catering bookings and does not include
                  any taxes or fees.
                </p>
              </div>

              <h3 className="text-white text-3xl mt-4">What are some popular cuisines for food truck catering?</h3>
              <div className={[styles.faq, 'text-white text-lg']}>
                <p>
                  Here are our top 10 most requested food truck cuisines:
                </p>
                <ul className="pl-2 list-disc list-inside">
                  <li className="font-light">
                    <a href="/food-truck-catering/taco-truck-catering/" target="blank" className="text-blue-400">Tacos</a> (Mexican and Fusion)
                  </li>
                  <li className="font-light">
                    <a href="/food-truck-catering/burger-catering-truck/" target="blank" className="text-blue-400">Burgers</a>
                  </li>
                  <li className="font-light">
                    <a href="/food-truck-catering/bbq-food-truck-catering/" target="blank" className="text-blue-400">BBQ</a>
                  </li>
                  <li className="font-light">
                    <a href="/food-truck-catering/pizza-truck-catering/" target="blank" className="text-blue-400">Pizza</a>
                  </li>
                  <li>Sandwiches</li>
                  <li>Asian</li>
                  <li>Dessert </li>
                  <li>Healthy Food</li>
                  <li>Mediterranean</li>
                  <li>Breakfast</li>
                </ul>
                <p>
                  We also get many requests for Vegetarian and Vegan options, which many food trucks
                  can accommodate.
                </p>
              </div>
              <h3 className="text-white text-3xl mt-4">How do I get catering packages for food trucks near me?</h3>
              <div className={[styles.faq, 'text-white text-lg']}>
                <p>
                  If your event details are confirmed, and your budget meets the minimum (see above), you can
                  get catering packages and quotes by submitting a catering request.
                </p>
                <p>
                  Roaming Hunger will send your request to food trucks in your area, and available trucks will
                  submit a catering package with pricing and menu. From there you can choose the best catering
                  package for you.
                </p>
                <p>
                  You can learn more about food truck catering
                  <a
                    href="https://booking.helpscoutdocs.com/category/249-catering-events"
                    target="blank"
                    className="underline text-blue-400"
                  >
                    &nbsp; here.
                  </a>
                </p>
              </div>
              <h3 className="text-white text-3xl mt-4">Why should I use Roaming Hunger?</h3>
              <div className={[styles.faq, 'text-white text-lg']}>
                <p>
                  Roaming Hunger is the largest website for food trucks and mobile food businesses. Since 2009,
                  we’ve helped thousands of companies and individuals book food trucks for catering and events.
                  Today, we have over 18,500 independent food trucks, trailers, carts, and pop-ups on our site.
                </p>
                <ol className="pl-6 list-disc list-outside">
                  <li><strong>Variety</strong> - Our network consists of hundreds of local vendors in every major city, totaling 18,000+ nationally.</li>
                  <li><strong>Save Time</strong> - Why have 100 conversations to find one food truck when you can have one conversation to find 100 food trucks?!</li>
                  <li><strong>Speed</strong> - Whether you are ready to book one day or one month out from your event, we will provide every available food truck option.</li>
                  <li><strong>Quality Options</strong> - All the food trucks we work with are gourmet, vetted, licensed, proven and reliable.</li>
                  <li><strong>Service</strong> - Every catering is protected by our 100% Roaming Hunger Service Guarantee. If the caterer does not show, we’ll get you a replacement caterer or refund 100% of your money.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
